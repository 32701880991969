import { getTenant } from "../../lib/utils";

const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios");

const request = (url, data, method, settings) => {
  const tenant = getTenant(settings);
  return axios({
    url: baseURL + url,
    method,
    data,
    headers: {
      Authorization: "Basic SP1915CBD-EC15-44DA-A572-079BC9857355",
      "Content-Type": "application/json",
      "x-tenant": tenant,
    },
  });
};

const loading = (action, key) => ({
  type: action + "/LOADING",
  status: "loading",
  payload: {},
  key,
});

const success = (action, response, key, additionalPayloadData) => ({
  type: action + "/SUCCESS",
  status: "loaded",
  payload: { data: response.data, ...additionalPayloadData },
  key,
});

const update = (action, response, key, additionalPayloadData) => ({
  type: action + "/UPDATE",
  status: "loaded",
  payload: { data: response.data, error: null, ...additionalPayloadData },
  key,
});

const error = (action, err, key) => ({
  type: action + "/ERROR",
  status: "error",
  payload: { data: {}, error: err.response },
  key,
});

export const api = (store) => (next) => (action) => {
  if (action.type === "API") {
    const requestInfos = action.meta;
    const actionName = action.meta.action;
    const key = action.meta.key || null;
    const additionalPayloadData = action.meta.additionalPayloadData || {};

    store.dispatch(loading(actionName, key));
    const deferred = request(
      requestInfos.url,
      requestInfos.params || {},
      requestInfos.method || "GET",
      store.getState().settings
    );

    deferred.then((response) => {
      if (requestInfos.method === "POST") {
        store.dispatch(
          success(actionName, response, key, additionalPayloadData)
        );
      } else {
        store.dispatch(
          update(actionName, response, key, additionalPayloadData)
        );
      }
    });

    deferred.catch((err) => store.dispatch(error(actionName, err, key)));
  }

  next(action);
};
