import React, { Component } from 'react';
import style from './Section.module.scss';
import PropTypes from 'prop-types';
import Icon from '../general/Icon';
import { Link } from 'react-router-dom';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsable
    };
    this.contentRef = React.createRef();
  }

  getCollapsableStyle = () => {
    if (this.props.collapsable && this.state.collapsed) {
      return { maxHeight: '0', padding: 0 };
    }

    if (!this.props.collapsable) {
      return { maxHeight: 'none' };
    }

    return { maxHeight: this.contentRef.current.offsetHeight + 'px' };
  };

  getIndicator = () => {
    if (!this.props.collapsable) {
      return null;
    }

    if (this.state.collapsed) {
      return <Icon name="plus" size="15px" color="#0a8d7f" />;
    } else {
      return <Icon name="minus" size="15px" color="#0a8d7f" />;
    }
  };

  togglePanel() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  addPropsToArray = targetArray =>
    targetArray.map(target =>
      React.cloneElement(target, { visible: !this.state.collapsed })
    );

  addPropsToElement = target =>
    React.cloneElement(target, { visible: !this.state.collapsed });

  renderContent = content => {
    const contentWithProps = Array.isArray(content)
      ? this.addPropsToArray(content)
      : this.addPropsToElement(content);

    return (
      <div className={style.content} style={this.getCollapsableStyle()}>
        <div className={style.contentWrap} ref={this.contentRef}>
          {contentWithProps}
        </div>
      </div>
    );
  };

  render() {
    const label = this.props.label && (
      <div className={style.label}>{this.props.label}</div>
    );

    const title = this.props.returnUrl ? (
      <Link to={this.props.returnUrl} className={style.backLinkWrap}>
        <div className={style.backLink}>
          <Icon name="arrowLeft" size="15px" color="#148bbc" />
          {this.props.title}
        </div>
      </Link>
    ) : (
      this.props.title
    );

    return (
      <div className={style.panel}>
        <header className={style.title} onClick={this.togglePanel.bind(this)}>
          {title} {label}
          <i className={style.indicator}>{this.getIndicator()}</i>
        </header>
        {this.props.content && this.renderContent(this.props.content)}
      </div>
    );
  }
}

Section.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.any,
  collapsable: PropTypes.bool,
  returnUrl: PropTypes.string
};

export default Section;
