import React from "react";
import Button from "./Button";
import style from "./InputButtonRow.module.scss";

const InputButtonRow = props => {
  return (
    <div className={style.row}>
      <div className={style.input}>{props.input}</div>
      <div className={style.button}>
        <Button loading={props.loading} onClick={props.onSubmit}>
          {props.buttonText}
        </Button>
      </div>
    </div>
  );
};

export default InputButtonRow;
