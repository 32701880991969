import types from './types';

export default function(state = {}, action) {
  switch (action.type) {
    case types.SAVED:
    case types.SAVING:
    case types.SAVING_PRODUCTS:
      return {
        [action.key]: {
          ...state[action.key],
          ...action.payload,
          status: action.status
        }
      };

    case types.PRODUCTS_SAVED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.payload,
          status: 'saved'
        }
      };

    case types.RESET:
      return {};

    default:
      return state;
  }
}
