import React from 'react';
import TextInput from '../forms/TextInput';
import Button from '../forms/Button';
import FormLayout from '../forms/FormLayout';
import style from './PanelUserRegistration.module.scss';

const PanelUserRegistration = props => {
  const customer = props.customer;
  const onChange = props.onChange;
  const onSubmit = props.onSubmit;
  const nome = <TextInput name="nome" onChange={onChange} placeholder="Nome" />;

  const email = (
    <TextInput
      name="email"
      placeholder="E-mail"
      type="email"
      onChange={onChange}
    />
  );

  const telefone = (
    <TextInput
      name="telefone"
      onChange={onChange}
      placeholder="Telefone"
      type="tel"
      mask="(99)99999-9999"
    />
  );

  const button = (
    <Button loading={customer && customer.status === 'loading'}>
      Cadastrar
    </Button>
  );

  return (
    <div className={style.formContainer}>
      <FormLayout
        onSubmit={onSubmit}
        fields={[nome, telefone, email]}
        buttons={[button]}
      />
    </div>
  );
};

export default PanelUserRegistration;
