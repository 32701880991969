import React from "react";
import Icon from "../general/Icon";
import style from "./WarningBadge.module.scss";

const WarningBadge = props => {
  const color = props.color ? props.color : "#3b4759";

  return (
    <i className={style.icon} aria-hidden="true">
      <Icon name="exclamation" size="15px" color={color} />
    </i>
  );
};

export default WarningBadge;
