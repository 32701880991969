import React from "react";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";
import style from "./CollapsableItem.module.scss";
import Icon from "../general/Icon";

const CollapsableItem = props => {
  const title = (
    <React.Fragment>
      {props.title}
      <div className={style.closeIcon}>
        <Icon size="16px" color="#13b4a1" name="arrowUp" />
      </div>
      <div className={style.openIcon}>
        <Icon size="16px" color="#13b4a1" name="arrowDown" />
      </div>
    </React.Fragment>
  );

  return (
    <div className={style.item}>
      <Collapsible trigger={title}>{props.children}</Collapsible>
    </div>
  );
};

CollapsableItem.propTypes = {
  title: PropTypes.string.isRequired
};

export default CollapsableItem;
