import React from 'react';
import style from './MessageBadge.module.scss';
import Icon from '../general/Icon';

const MessageBadge = props => {
  return (
    <div className={style.badgeContainer}>
      <div className={style.icon}>
        <Icon name="lamp" color="#ffffff" size="23px" />
      </div>
      <div className={style.badge}>
        <h3>Motivo para o contato</h3>
        {props.dica}
      </div>
    </div>
  );
};

export default MessageBadge;
