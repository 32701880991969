import React from 'react';
import Section from '../panels/Section';
import ChartBarContainer from '../charts/ChartBarContainer';
import {
  checkUserProfile,
  removeFromArray,
  getGrupoLabel
} from '../../lib/utils';
import { connect } from 'react-redux';

const PanelPercentagemAproveitamentoContato = ({
  summary,
  isStore,
  isStoreGroup,
  session
}) => {
  const contacts = summary.ResumosContatoVendedor;

  if (!contacts || contacts.length === 0) {
    return null;
  }

  const groupLabel = getGrupoLabel(session.user, isStore, isStoreGroup);
  let data = [
    { label: 'Seu', value: contacts[0].percent_vendas_contatos },
    { label: 'Loja', value: contacts[0].Percent_Vendas_Contatos_Loja },
    { label: groupLabel, value: contacts[0].Percent_Vendas_Contatos_GerSup },
    { label: 'Rede', value: contacts[0].Percent_Vendas_Contatos_Rede }
  ];

  if (isStore) {
    data = removeFromArray(data, 'label', 'Seu');
  } else {
    data = removeFromArray(data, 'label', groupLabel);
  }

  if (isStore && checkUserProfile(session, 'gerente')) {
    data = removeFromArray(data, 'label', 'Loja');
  }

  if (isStoreGroup) {
    data = removeFromArray(data, 'label', 'Loja');
  }

  const chart = <ChartBarContainer data={data} percentage={true} />;
  return (
    <Section
      title="% de Aproveitamento de Contatos"
      content={chart}
      collapsable={true}
    />
  );
};

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps)(PanelPercentagemAproveitamentoContato);
