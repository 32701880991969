import React from "react";
import Accordion from "../panels/Accordion";
import ContactListItem from "../lists/ContactListItem";

const PanelLastContacts = ({ customer }) => (
  <div>
    <Accordion title="Lista de Últimos Contatos Realizados">
      {customer.Contatos.map(contact => (
        <ContactListItem contact={contact} />
      ))}
    </Accordion>
  </div>
);

export default PanelLastContacts;
