import React from "react";
import style from "./Button.module.scss";
import classNames from "classnames";
import Loading from "../general/Loading";

const Button = props => {
  const classList = {
    [style.button]: true,
    [style.loading]: props.loading,
    [props.className]: props.className
  };

  return (
    <button
      {...props}
      className={classNames(classList)}
      disabled={props.loading}
    >
      {props.children}
      {props.loading && (
        <span className={style.loaderContainer}>
          <Loading showText={false} />
        </span>
      )}
    </button>
  );
};

export default Button;
