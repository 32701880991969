import { useState } from "react";
import React from "react";
import TextInput from "../forms/TextInput";
import ActionButton from "../general/ActionButton";
import style from "./FormProspect.module.scss";
import FormProspectField from "./FormProspectField";
import CustomerSummary from "../customers/CustomerSummary";

const FormProspect = (props) => {
  const [values, setValues] = useState({});
  const [negotiation, setNegotiation] = useState({});

  const onChangeValue = (e) => {
    e.persist();
    setValues((values) => {
      if (props.onUpdateField) {
        props.onUpdateField(e.target.name, e.target.value);
      }
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  /**
   * Transforma os valores armazenados em negotiation na estrutura aceita pela API
   */
  const prepareNegotiation = () => {
    let negotiationArray = [];
    for (var id in negotiation) {
      negotiationArray.push({
        cd_campo: parseInt(id),
        cd_campo_valor: negotiation[id].value,
        tx_valor_preenchido: negotiation[id].text || "",
      });
    }
    return negotiationArray;
  };

  /**
   * Quando o formulário é enviado, passa os valores corretos para a propriedade onSubmit, definida
   * na "page" pages/prospectInsert ou pages/prospectUpdate
   */
  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      ...values,
      negotiation: prepareNegotiation(negotiation),
    });
  };

  /**
   * Quando os valores da negociação são alterados, armazena no estado "negotiation" com uma
   * estrutura fácil de se processar pela função prepareNegotiation, no momento que o formulário é enviado.
   */
  const parseNegotiationField = (negotiation, name, value, isText) => {
    let newValues = { ...negotiation };
    let correctName = name.replace("additional-", "");

    if (!newValues.hasOwnProperty(correctName)) {
      newValues[correctName] = {};
    }

    if (isText) {
      newValues[correctName].text = value;
    } else {
      newValues[name].value = value;
    }

    return newValues;
  };

  const parseErrors = (errors) => {
    return errors.map((err) => <li className={style.errorItem}>{err}</li>);
  };

  /**
   * No momento em que um campo (select ou texto (tipo 2)) é alterado, essa função é chamada.
   * Ver as propriedades do componente <FormProspectField />
   */
  const onChangeField = (e) => {
    const isSelectBox = e.target.type === "select-one";
    const name = e.target.name;
    const value = isSelectBox ? parseInt(e.target.value) : e.target.value;

    setNegotiation((negotiation) =>
      parseNegotiationField(negotiation, name, value, !isSelectBox)
    );
  };

  /**
   * Renderiza os campos de cadastro do usuário. Chamado caso o parâmetro props.user
   * não seja passado (ou seja, usuário não existir)
   */
  const renderCustomerFields = () => {
    return (
      <React.Fragment>
        <div className={style.row}>
          <TextInput
            id="name"
            name="name"
            icon="user"
            placeholder="Nome *"
            value={values.name}
            onChange={onChangeValue}
          />
        </div>
        <div className={style.row}>
          <TextInput
            id="phone"
            name="phone"
            icon="phone"
            value={values.phone}
            placeholder="Telefone *"
            onChange={onChangeValue}
          />
        </div>
        <div className={style.row}>
          <TextInput
            id="email"
            name="email"
            icon="email"
            value={values.email}
            placeholder="E-mail"
            onChange={onChangeValue}
          />
        </div>
        <div className={style.row}>
          <TextInput
            id="cpf"
            name="cpf"
            mask="999.999.999-99"
            value={values.cpf}
            placeholder="CPF"
            onChange={onChangeValue}
          />
        </div>
      </React.Fragment>
    );
  };

  const renderCustomerPanel = () => {
    return <CustomerSummary displayName={true} customer={props.customer} />;
  };

  return (
    <form onSubmit={onSubmit}>
      {props.customer ? renderCustomerPanel() : renderCustomerFields()}

      <h3 className={style.title}>Negociação</h3>
      {props.fields &&
        props.fields.map((field) => (
          <FormProspectField onChange={onChangeField} field={field} />
        ))}

      {props.errors && (
        <div className={style.error}>{parseErrors(props.errors)}</div>
      )}

      <ActionButton>Cadastrar</ActionButton>
    </form>
  );
};

export default FormProspect;
