import React from "react";
import style from "./login.module.scss";
import { connect } from "react-redux";
import { grantAccess } from "../store/ducks/access";
import { addSetting } from "../store/ducks/settings";
import { handleInputChange } from "../lib/utils";
import { Redirect } from "react-router-dom";
import logo from "../static/assets/sp-logo-big.png";
import PageCentered from "../views/layouts/PageCentered";
import FormLayout from "../views/forms/FormLayout";
import TextInput from "../views/forms/TextInput";
import ActionButton from "../views/general/ActionButton";
import LoadingPanel from "../views/general/LoadingPanel";
import ErrorPanel from "../views/warnings/ErrorPanel";

class GrantAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        Login: "",
        Senha: "",
        Tenant: "",
      },
    };
    this.buttons = [<ActionButton onClick={() => {}}>Entrar</ActionButton>];
  }

  onLogin() {
    debugger;
    if (this.state.user.Tenant) {
      this.props.addSetting("tenant", this.state.user.Tenant);
    }
    this.props.onLogin(this.state.user);
  }

  render() {
    const { access } = this.props;
    const loading = access.granted && access.granted.status === "loading";
    const error = access.granted && access.granted.error;
    const success = access.granted && access.granted.data;
    console.log(this.props);
    if (success) {
      return <Redirect to="/liberar-acesso/sucesso" />;
    }

    const fields = [];

    if (
      !process.env.REACT_APP_FIXED_TENANT ||
      process.env.REACT_APP_FIXED_TENANT === "0" ||
      process.env.REACT_APP_FIXED_TENANT === "false"
    ) {
      fields.push(
        <TextInput
          id="tenant"
          name="Tenant"
          icon="store"
          value={this.state.user.Tenant}
          onChange={handleInputChange.bind(this, "user")}
          placeholder="Conta"
        />
      );
    }

    fields.push(
      <TextInput
        id="login"
        name="Login"
        icon="user"
        value={this.state.user.Login}
        onChange={handleInputChange.bind(this, "user")}
        placeholder="Usuário do GERENTE"
      />
    );

    fields.push(
      <TextInput
        id="senha"
        name="Senha"
        type="password"
        icon="lock"
        value={this.state.user.Senha}
        onChange={handleInputChange.bind(this, "user")}
        placeholder="Senha do GERENTE"
      />
    );

    return (
      <PageCentered>
        <i className={style.logo}>
          <img alt="" src={logo} />
        </i>
        <p className={style.intro}>
          Gerente, acesse o sistema para liberar acesso de toda a loja.
        </p>
        <ErrorPanel errorMessage={error} />
        <FormLayout
          onSubmit={this.onLogin.bind(this)}
          fields={fields}
          buttons={this.buttons}
        />
        <LoadingPanel isLoading={loading} />
      </PageCentered>
    );
  }
}

const mapStateToProps = ({ access }) => ({ access });

const mapDispatchToProps = {
  onLogin: grantAccess,
  addSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrantAccess);
