import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import Section from '../views/panels/Section';
import SelectBox from '../views/forms/SelectBox';
import FormLayout from '../views/forms/FormLayout';
import Button from '../views/forms/Button';
import { loadSellersByStore } from '../store/ducks/sellers';
import { loadStoreList } from '../store/ducks/stores';
import { setSelectedUser } from '../store/ducks/session';
import style from './selectSeller.module.scss';

class SelectStore extends Component {
  state = {
    store: '',
    seller: ''
  };

  componentWillMount() {
    this.props.loadStoreList(this.props.session.user.VendedorId);
  }

  getSellersList() {
    if (this.props.sellers.list.status === 'loaded') {
      return this.props.sellers.list.data
        .map(s => ({
          value: s.Id,
          name: s.Nome
        }))
        .sort((v1, v2) => (v1.name > v2.name ? 1 : -1));
    }
    return [];
  }

  getStoreList() {
    const storeList = this.props.stores.list;

    if (storeList.status === 'loaded' && !storeList.error) {
      return storeList.data
        .map(s => ({
          value: s.Id,
          name: s.Nome
        }))
        .sort((v1, v2) => (v1.name > v2.name ? 1 : -1));
    }

    return [];
  }

  goToTargetPage() {
    const currentUser = this.state.seller || '';
    this.props.setSelectedUser(currentUser);

    if (this.state.store && currentUser) {
      this.props.history.push('/dashboard');
    } else if (this.state.store) {
      this.props.history.push('/dashboard?store=' + this.state.store);
    } else {
      this.props.history.push('/dashboard?store=all');
    }
  }

  loadSellers(value) {
    this.setState({ store: value, seller: '' });
    this.props.loadSellersByStore(value);
  }

  renderSectionContent() {
    const selectLoja = (
      <SelectBox
        placeholder="(Todas as Lojas)"
        loading={this.props.stores.list.status === 'loading'}
        items={this.getStoreList()}
        onChange={e => this.loadSellers(e.target.value)}
      />
    );

    const selectVendedor = (
      <SelectBox
        disabled={this.props.sellers.list.length === 0}
        placeholder="(Todos os Vendedores)"
        loading={this.props.sellers.list.status === 'loading'}
        items={this.getSellersList()}
        onChange={e => this.setState({ seller: e.target.value })}
      />
    );

    const submitButton = (
      <Button onClick={this.goToTargetPage.bind(this)}>Acessar</Button>
    );

    return (
      <FormLayout
        fields={[selectLoja, selectVendedor]}
        buttons={[submitButton]}
      />
    );
  }

  render() {
    return (
      <AuthManager accessLevel="gerente">
        <PageInternal>
          <div className={style.container}>
            <Section
              content={this.renderSectionContent()}
              title="Seleção de Loja/Vendedor"
              collapsable={false}
            />
          </div>
        </PageInternal>
      </AuthManager>
    );
  }
}

const mapDispatchToProps = {
  loadSellersByStore,
  loadStoreList,
  setSelectedUser
};
const mapStateToProps = ({ session, sellers, stores }) => ({
  session,
  sellers,
  stores
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectStore)
);
