import types from "./types";
import { getRequest } from "../../../lib/api";

export const searchProduct = productCode => (dispatch, getState) => {
  const currentState = getState();
  const isAlreadyLoaded =
    currentState.products && currentState.products[productCode];

  if (isAlreadyLoaded) {
    return false;
  }

  dispatch({ type: types.SEARCH_PRODUCT, productCode });
  getRequest("/aviseme/produtos?codRef=" + productCode, { codRef: productCode })
    .then(el => {
      if (el.data && el.data.length > 0) {
        dispatch({
          type: types.UPDATE_PRODUCT,
          productCode,
          payload: el.data
        });
      } else {
        dispatch({
          type: types.PRODUCT_NOT_FOUND,
          productCode
        });
      }
    })
    .catch(err => {});
};
