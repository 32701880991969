import types from './types';
export function login(credentials) {
  return dispatch => {
    dispatch({
      type: 'API',
      meta: {
        url: '/usuarios/login/jwt',
        method: 'POST',
        action: types.USER_LOGIN,
        params: credentials
      }
    });
  };
}

export const logoutUser = () => dispatch => {
  window.sessionStorage.clear();
  window.localStorage.clear();
  dispatch({ type: types.USER_LOGOUT });
};

export const setSelectedUser = VendedorId => dispatch => {
  dispatch({ type: types.SET_CURRENT_VIEW, payload: VendedorId });
};

export const updateUserInfo = (user, newUserCredentials) => {
  const userData = {
    ...user,
    Nome: newUserCredentials.Nome,
    Senha: newUserCredentials.Senha
  };

  return dispatch => {
    dispatch({
      type: 'API',
      meta: {
        url: '/usuarios/atualizar',
        method: 'POST',
        params: userData,
        action: types.USER_UPDATE
      }
    });
  };
};
