import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAt,
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faLock,
  faMinus,
  faMinusCircle,
  faPhone,
  faPlus,
  faPlusCircle,
  faSpinner,
  faTable,
  faUser,
  faChevronDown,
  faChevronUp,
  faStore,
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';

import iconIdea from '../../static/assets/ico-tip.png';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import {
  faCalendarAlt,
  faCommentDots
} from '@fortawesome/free-regular-svg-icons';

import style from './Icon.module.scss';

function Icon(props) {
  const map = {
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight,
    arrowDown: faChevronDown,
    arrowUp: faChevronUp,
    calendar: faCalendarAlt,
    comment: faCommentDots,
    email: faAt,
    exclamation: faExclamation,
    idea: iconIdea,
    loading: faSpinner,
    lock: faLock,
    minus: faMinus,
    minusCircle: faMinusCircle,
    phone: faPhone,
    plus: faPlus,
    plusCircle: faPlusCircle,
    table: faTable,
    user: faUser,
    store: faStore,
    whatsapp: faWhatsapp,
    lamp: faLightbulb
  };

  const images = ['idea'];

  if (!props.name || !map.hasOwnProperty(props.name)) {
    return null;
  }

  if (images.includes(props.name)) {
    return <img alt="" style={{ margin: '0 auto' }} src={map[props.name]} />;
  }

  const styles = {
    fontSize: props.size || '20px',
    color: props.color || ''
  };

  return (
    <FontAwesomeIcon
      className={style.icon}
      icon={map[props.name]}
      style={styles}
    />
  );
}

export default Icon;
