import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verify } from '../../store/ducks/access';
import { withRouter, Redirect } from 'react-router-dom';

class VerifyConnection extends Component {
  constructor(props) {
    super(props);
    this.exceptions = this.props.exceptions;
  }

  componentDidMount() {
    const verifyIpInterval =
      process.env.REACT_APP_VERIFY_IP_INTERVAL_IN_SECONDS;

    if (verifyIpInterval === 0) {
      return;
    }

    this.props.verify();
    setInterval(() => this.props.verify(), verifyIpInterval * 1000 || 30000);
  }

  shouldIntercept() {
    const { access, session, location, exceptions } = this.props;

    if (session && session.user && session.user.Perfil > 1) {
      return false;
    }

    if (!access || !access.verified || !access.verified.data) {
      return false;
    }

    return (
      access.verified.status === 'error' &&
      !exceptions.includes(location.pathname)
    );
  }

  showContents() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }

  render() {
    if (this.shouldIntercept()) {
      if (this.props.access.verified.error.status === 410) {
        if (this.props.session.user) {
          return <Redirect to="/fora-do-horario" />;
        }

        return this.showContents();
      }

      return <Redirect to="/acesso-negado" />;
    }

    return this.showContents();
  }
}

const mapStateToProps = ({ access, session }) => ({ access, session });

const mapDispatchToProps = { verify };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyConnection)
);
