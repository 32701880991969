import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadSchedule } from '../store/ducks/sellers';
import AuthManager from '../views/enhancers/AuthManager';
import Schedule from '../views/schedule/Schedule';
import Loading from '../views/general/Loading';
import PageInternal from '../views/layouts/PageInternal';
import ErrorPanel from '../views/warnings/ErrorPanel';
export class ScheduleContainer extends Component {
  componentWillMount() {
    const { session, loadSchedule } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);

    if (session && session.user && session.user.Perfil === 1) {
      loadSchedule(session.user.VendedorId, queryParams.has('reload'));
    }

    if (session && session.user && session.user.Perfil >= 2) {
      loadSchedule(
        session.viewAs || session.user.VendedorId,
        queryParams.has('reload')
      );
    }
  }

  getSeller() {
    const session = this.props.session;

    if ((session.user && session.user.VendedorId) || session.viewAs) {
      return this.props.sellers[session.viewAs || session.user.VendedorId];
    }

    return null;
  }

  getScheduleData(seller) {
    if (seller && seller.schedule.status !== 'loading') {
      return seller.schedule.data;
    }

    return null;
  }

  getSchedules = scheduleData =>
    this.sortSchedules(scheduleData).reduce((obj, value) => {
      if (!obj[value.Nome]) {
        obj[value.Nome] = [];
      }

      obj[value.Nome] = obj[value.Nome].concat(value);
      return obj;
    }, {});

  getLoadingPanel() {
    return (
      <div style={{ textAlign: 'center', paddingTop: '30px' }}>
        <Loading />
      </div>
    );
  }

  sortSchedules = schedules =>
    schedules.sort((s1, s2) => (s1.NO_PRIORIDADE < s2.NO_PRIORIDADE ? -1 : 1));

  getContent() {
    const currentSeller = this.getSeller();
    if (!currentSeller) {
      return null;
    }

    if (currentSeller.schedule.status === 'loading') {
      return this.getLoadingPanel();
    }

    if (currentSeller.error) {
      return <ErrorPanel errorMessage={currentSeller.error.data.Message} />;
    }

    const scheduleData = this.getScheduleData(currentSeller);
    const schedules = this.getSchedules(scheduleData);
    return <Schedule data={schedules} seller={currentSeller} />;
  }

  render() {
    return (
      <AuthManager only={['vendedor', 'gerente', 'admin']}>
        <PageInternal>{this.getContent()}</PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ session, sellers }) => ({ session, sellers });

const mapDispatchToProps = { loadSchedule };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleContainer);
