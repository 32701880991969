import React, { Component } from 'react';
import { Provider } from 'react-redux';
import rootReducer from './store';
import { Route } from 'react-router-dom';
import { Router } from 'react-router';
import createMemoryHistory from 'history/createMemoryHistory';
import createHashHistory from 'history/createHashHistory';
import routes from './routes';
import HtmlStructure from './views/layouts/HtmlStructure';
import VerifyConnection from './views/general/VerifyConnection';

class App extends Component {
  componentWillMount() {
    this.history =
      typeof window !== 'undefined'
        ? createHashHistory()
        : createMemoryHistory();
  }

  render() {
    const exceptions = [
      '/login',
      '/acesso-negado',
      '/liberar-acesso',
      '/liberar-acesso/sucesso',
      '/fora-do-horario'
    ];

    return (
      <Provider store={rootReducer({})}>
        <Router history={this.history}>
          <VerifyConnection exceptions={exceptions}>
            <HtmlStructure>
              {routes.map(route => (
                <Route {...route} />
              ))}
            </HtmlStructure>
          </VerifyConnection>
        </Router>
      </Provider>
    );
  }
}

export default App;
