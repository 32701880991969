import React, { Component } from 'react';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import {
  loadCustomer,
  loadVigencia,
  loadMotivosInsucesso
} from '../store/ducks/customers';
import { connect } from 'react-redux';
import SingleContactPage from '../views/contact/SingleContactPage';

class ContactContainer extends Component {
  loadContactPanel(customerId) {
    const customer = this.props.customers && this.props.customers[customerId];

    if (!this.props.customers.MotivosInsucesso) {
      this.props.loadMotivosInsucesso();
      return null;
    }

    if (!customer) {
      this.props.loadCustomer(customerId);
      this.props.loadVigencia(customerId);
      return null;
    }

    if (customer && !customer.Vigencia) {
      this.props.loadVigencia(customerId);
      return null;
    }

    if (customer.error) {
      // TODO: Tratar os erro tudo
    }

    if (customer.Vigencia.error) {
      // TODO: Tratar os dados de Vigência
    }

    return (
      <SingleContactPage
        regra={this.props.match.params.regra}
        tipoContato={this.props.match.params.tipo}
        customer={customer}
      />
    );
  }

  render() {
    const customerId = this.props.match.params.clienteId;
    return (
      <AuthManager>
        <PageInternal>{this.loadContactPanel(customerId)}</PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ customers }) => ({ customers });

const mapDispatchToProps = { loadCustomer, loadVigencia, loadMotivosInsucesso };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactContainer);
