import React, { Component } from 'react';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import Customer from '../views/customers/Customer';
import { loadCustomer } from '../store/ducks/customers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class CustomerContainer extends Component {
  isCustomerLoading(customerId) {
    const customers = this.props.customers;
    return (
      customers &&
      customers[customerId] &&
      customers[customerId].status === 'loading'
    );
  }

  isCustomerLoaded(customerId) {
    const customers = this.props.customers;
    return (
      customers &&
      customers[customerId] &&
      customers[customerId].status === 'loaded' &&
      customers[customerId].data.Resumo
    );
  }

  renderCustomer(customerId) {
    if (
      !this.isCustomerLoaded(customerId) &&
      !this.isCustomerLoading(customerId)
    ) {
      this.props.loadCustomer(customerId);
      return null;
    }

    const showContactInfo = this.props.location.search === '?agenda';

    return (
      <Customer
        returnUrl={showContactInfo ? '/agendas' : '/pesquisa-cliente'}
        showContactInfo={showContactInfo}
        customer={this.props.customers[customerId]}
      />
    );
  }

  render() {
    const customerId = this.props.match.params.clienteId;
    return (
      <AuthManager>
        <PageInternal>{this.renderCustomer(customerId)}</PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ customers }) => ({ customers });

const mapDispatchToProps = { loadCustomer };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerContainer)
);
