import React from "react";
import PropTypes from "prop-types";
import Icon from "../general/Icon";
import style from "./PeopleListItem.module.scss";
import { Link } from "react-router-dom";

const PeopleListItem = props => {
  return (
    <Link
      to={`/clientes/${props.data.CodCliente}?agenda`}
      className={style.item}
    >
      <span className={style.client}>{props.data.NomeCliente}</span>

      <span className={style.arrow}>
        <Icon name="arrowRight" color="#2fcdba" size="15px" />
      </span>
    </Link>
  );
};

PeopleListItem.propTypes = {
  data: PropTypes.object
};

export default PeopleListItem;
