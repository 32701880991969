import React from "react";
import Accordion from "../panels/Accordion";
import Purchase from "../lists/Purchase";

const PanelLastPurchase = ({ customer }) => {
  const getLastPurchase = customer =>
    customer.HistoricoCompras &&
    customer.HistoricoCompras.length > 0 &&
    customer.HistoricoCompras[0];

  const lastPurchase = getLastPurchase(customer);
  if (!lastPurchase) return null;

  return (
    <div>
      <Accordion title="Última compra">
        <Purchase purchase={lastPurchase} />
      </Accordion>
    </div>
  );
};

export default PanelLastPurchase;
