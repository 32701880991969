import login from "./pages/login";
import dashboard from "./pages/dashboard";
import schedule from "./pages/schedule";
import customer from "./pages/customer";
import prospectInsert from "./pages/prospectInsert";
import prospectUpdate from "./pages/prospectUpdate";
import contact from "./pages/contact";
import singleContact from "./pages/singleContact";
import report from "./pages/report";
import warnme from "./pages/warnme";
import logout from "./pages/logout";
import search from "./pages/search";
import searchInclude from "./pages/searchInclude";
import comments from "./pages/comments";
import selectSeller from "./pages/selectSeller";
import selectStore from "./pages/selectStore";
import accessDenied from "./pages/accessDenied";
import grantAccess from "./pages/grantAccess";
import grantAccessSuccess from "./pages/grantAccessSuccess";
import outsideBusinessHours from "./pages/outsideBusinessHours";

export default [
  { path: "/", component: login, exact: true },
  { path: "/dashboard", component: dashboard, exact: true },
  { path: "/dashboard/loja/:sellerId", component: selectSeller },
  { path: "/agendas", component: schedule, exact: true },
  { path: "/relatorios", component: report, exact: true },
  { path: "/anotacoes", component: comments, exact: true },
  { path: "/avise-me", component: warnme, exact: true },
  { path: "/logout", component: logout, exact: true },
  { path: "/contato/:clienteId/:tipo/:regra", component: contact },
  { path: "/contatoavulso/:clienteId/:tipo/:regra", component: singleContact },
  { path: "/clientes/:clienteId", component: customer },
  { path: "/pesquisa-cliente", component: search, exact: true },
  { path: "/cadastro-cliente", component: searchInclude, exact: true },
  { path: "/cadastro-negociacao", component: prospectInsert, exact: true },
  {
    path: "/cadastro-negociacao/:clienteId",
    component: prospectUpdate,
    exact: true,
  },
  { path: "/selecionar-vendedor", component: selectSeller, exact: true },
  { path: "/selecionar-loja", component: selectStore, exact: true },
  { path: "/fora-do-horario", component: outsideBusinessHours, exact: true },
  { path: "/acesso-negado", component: accessDenied, exact: true },
  { path: "/liberar-acesso", component: grantAccess, exact: true },
  {
    path: "/liberar-acesso/sucesso",
    component: grantAccessSuccess,
    exact: true,
  },
];
