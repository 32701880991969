import React from "react";
import style from "./Quote.module.scss";

const Quote = () => {
  return (
    <footer className={style.footerPanel}>
      <div className={style.container}>
        <div>
          <blockquote className={style.quote}>
            <h3>Faça um cliente, não uma venda.</h3>
            <h4>katherine barchetti</h4>
          </blockquote>
        </div>
      </div>
    </footer>
  );
};

export default Quote;
