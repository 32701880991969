import React from "react";
import PropTypes from "prop-types";
import style from "./PurchasedListItem.module.scss";
import { formatCurrency } from "../../lib/utils";

const PurchasedListItem = ({ item }) => {
  return (
    <li className={style.listItem}>
      <h3 className={style.itemName}>
        {`[${item.tx_ref_basica}] - ${item.tx_produto}`}
      </h3>
      <ul>
        <li>
          Cor: <strong>{item.PROD_DESC_COR}</strong>
        </li>
        <li>
          Tamanho: <strong>{item.PROD_TAMANHO}</strong>
        </li>
        <li>
          Quantidade: <strong>{item.no_qtd_itens}</strong>
        </li>
        <li>
          Valor: <strong>{formatCurrency(item.no_valor_item)}</strong>
        </li>
      </ul>
    </li>
  );
};

PurchasedListItem.propTypes = {
  item: PropTypes.object
};

export default PurchasedListItem;
