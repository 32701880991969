import React from 'react';
import style from './ContactListItem.module.scss';
import moment from 'moment';
import ContactPanel from '../customers/ContactPanel';

const ContactListItem = ({ contact }) => {
  const getNotes = () =>
    contact.TX_DESCRICAO && (
      <React.Fragment>
        <tr>
          <th className={style.anotacaoColumnTitle}>Anotações</th>
        </tr>
        <tr>
          <td colSpan="3" className={style.anotacaoColumn}>
            {contact.TX_DESCRICAO}
          </td>
        </tr>
      </React.Fragment>
    );

  const getContactMedia = () => {
    const id = contact.CD_TP_CONTATO;
    const types = ['email', 'phone', 'comment', 'whatsapp'];

    return <ContactPanel showOnly={[types[id]]} />;
  };

  const renderDesktopTable = () => (
    <table className={style.contactItemWrapDesktop}>
      <tr>
        <th className={style.dateColumn}>Data</th>
        <th className={style.agendaColumn}>Agenda</th>
        <th>Mídia utilizada</th>
      </tr>
      <tr>
        <td className={style.dateColumn}>{moment(contact.DT_CONTATO).format('DD/MM/YYYY')}</td>
        <td className={style.agendaColumn}>{contact.NOMEAGENDA}</td>
        <td className={style.midiaColumn}>{getContactMedia()}</td>
      </tr>
      {getNotes()}
    </table>
  );

  const renderMobileItem = (title, content) => {
    if (!content) {
      return null;
    }

    return (
      <div className={style.mobileItem}>
        <div className={style.mobileItemTitle}>{title}</div>
        <div className={style.mobileItemContent}>{content}</div>
      </div>
    );
  };

  const renderMobileList = () => (
    <div className={style.contactItemMobile}>
      <header className={style.header}>{moment(contact.DT_CONTATO).format('DD/MM/YYYY')}</header>
      {renderMobileItem('Agenda', contact.NOMEAGENDA)}
      {renderMobileItem('Mídia utilizada', getContactMedia())}
      {renderMobileItem('Anotações', contact.TX_DESCRICAO)}
    </div>
  );

  const isMobile = window.innerWidth <= 768;
  return <div className={style.contactItem}>{isMobile ? renderMobileList() : renderDesktopTable()}</div>;
};

export default ContactListItem;
