import React from "react";
import PropTypes from "prop-types";
import style from "./WarnMeListItem.module.scss";

const WarnMeListItem = ({ item }) => {
  
  return (

    <li className={style.listItem}>
          <h3 className={style.itemName}>
            {`[${item.ProdRefBasica}] - ${item.Observacao}`}
          </h3>
          <ul>
            {item.CorDescricao && 
              <li>
              Cor: <strong>{item.CorDescricao}</strong>
                </li>
            }
            {item.Tamanho && 
              <li>
                Tamanho: <strong>{item.Tamanho}</strong>
                </li>
            }
           
          </ul>
        </li>
      );

};

WarnMeListItem.propTypes = {
  item: PropTypes.object
};

export default WarnMeListItem;
