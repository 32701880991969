import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import * as reducers from './ducks';
import { loadState, saveState } from '../lib/localStorage';
import throttle from 'lodash/throttle';
import { api } from './middlewares/api';

export default function configureStore(initialState) {
  const rootReducer = combineReducers(reducers);
  const rehydratedState = {
    ...initialState,
    ...loadState()
  };

  const store = createStore(
    rootReducer,
    rehydratedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware, api))
  );

  store.subscribe(
    throttle(() => {
      saveState({
        session: store.getState().session,
        settings: store.getState().settings
      });
    }, 1000)
  );

  return store;
}
