import React, { Component } from 'react';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import ReportPage from '../views/reports/ReportPage';
import { connect } from 'react-redux';

class ReportContainer extends Component {
  render() {
    const user = this.props.session.user;
    return (
      <AuthManager accessLevel="vendedor">
        <PageInternal>
          <ReportPage user={user} />
        </PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(ReportContainer);
