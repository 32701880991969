import React from "react";
import Section from "../panels/Section";
import Table from "../lists/Table";
import { formatCurrency, formatPercentage } from "../../lib/utils";

const PanelSuaMetaRealizadoMes = ({ summary }) => {
  const header = ["Metas", "Valor", "Alcançado (%)"];
  const goal = Math.max(1, summary.TotalMetaGerenteSupervisor);
  const superGoal = Math.max(1, summary.TotalSuperMetaGerenteSupervisor);
  const sales = summary.TotalVendasGrupo;

  const rows = [
    ["Meta", formatCurrency(goal), formatPercentage(sales / goal)],
    [
      "Super Meta",
      formatCurrency(superGoal),
      formatPercentage(sales / superGoal)
    ]
  ];

  const table = <Table rows={rows} header={header} />;
  return (
    <Section
      title="Sua Meta Mensal X Realizado no Mês"
      content={table}
      collapsable={true}
    />
  );
};

export default PanelSuaMetaRealizadoMes;
