import React, { Component } from 'react';
import style from './PanelThermometer.module.scss';
import classnames from 'classnames';
import WarningBadge from '../badges/WarningBadge';
import IconBadge from '../badges/IconBadge';
import Button from '../forms/Button';
import { connect } from 'react-redux';
import { scheduleContact } from '../../store/ducks/contacts';
import { Redirect } from 'react-router-dom';

class PanelThermometer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      notes: ''
    };
  }

  getRateItem(n) {
    const classes = classnames(
      style.rate,
      this.state.rating === n && style.rateSelected
    );

    return (
      <li onClick={() => this.setState({ rating: n })} className={classes} />
    );
  }

  getScale = (n, text) => {
    const classes = classnames(
      style.labelScale,
      n === this.state.rating && style.labelScaleSelected
    );

    return <li className={classes}>{text}</li>;
  };

  // Faz o submit com os dados do contato
  submit = customer => {
    const idCustomer = this.props.customer.data.Resumo.Cd_Cliente;
    const contact = {
      thermometer: this.state.rating,
      regra: this.props.regra,
      reagendar: this.props.regra === 99999 ? true : false, // Se for da agenda fixa permite reagendar
      contatoRealizado: true,
      idTipoContato: this.props.tipoContato,
      vendedor: this.props.session.viewAs || this.props.session.user.VendedorId,
      notes: this.state.notes
    };

    this.props.scheduleContact(idCustomer, contact);
  };

  render() {
    const customer = this.props.customer;
    const customerId = customer.data.Resumo.Cd_Cliente;
    const contact = this.props.contacts[customerId] || {};


    // Se for contato de uma agenda comum enviamos para as agendas
    if (contact.status === 'saved' && this.props.regra !== 99999) {
      return <Redirect to={'/agendas?reload=true'} />;
    }

    // Se for contat da agenda avulsa damos o redirect para o dashboard
    if (contact.status === 'saved' && this.props.regra === 99999)
    {
      return <Redirect to={'/dashboard'} />;
    }

    return (
      <div className={style.panel}>
        <h3>Termômetro do Contato</h3>
        <div className={style.rating}>
          <ul className={style.grid}>
            {this.getRateItem(1)}
            {this.getRateItem(2)}
            {this.getRateItem(3)}
            {this.getRateItem(4)}
          </ul>

          <div
            className={classnames(
              style.scale,
              style['scale' + this.state.rating]
            )}
          />

          <ul className={style.label}>
            {this.getScale(1, 'Não quer mais contato')}
            {this.getScale(2, 'Não curtiu muito')}
            {this.getScale(3, 'Curtiu')}
            {this.getScale(4, 'Adorou')}
          </ul>

          <div className={style.title}>
            <WarningBadge />
            <span>Anotações</span>
            <IconBadge icon="idea" />
          </div>

          <div className={style.notes}>
            <textarea
              placeholder="Coloque aqui informações que podem te ajudar no próximo contato"
              className={style.textarea}
              onChange={e => this.setState({ notes: e.currentTarget.value })}
              value={this.state.notes}
            />
            <Button
              loading={contact.status === 'loading'}
              onClick={this.submit.bind(this, customer)}
              className={style.button}
            >
              Salvar
            </Button>

            {this.state.error && (
              <div className={style.error}>{this.state.error}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, contacts }) => ({ session, contacts });

const mapDispatchToProps = { scheduleContact };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelThermometer);
