import React from 'react';
import { connect } from 'react-redux';
import ChartBarContainer from '../charts/ChartBarContainer';
import Section from '../panels/Section';
import {
  checkUserProfile,
  removeFromArray,
  getGrupoLabel
} from '../../lib/utils';

const PanelTicketMedio = ({ summary, isStore, isStoreGroup, session }) => {
  const groupLabel = getGrupoLabel(session.user, isStore, isStoreGroup);
  let data = [
    { label: 'Seu', value: summary.TmVendedor },
    { label: 'Loja', value: summary.TmLoja },
    { label: groupLabel, value: summary.TMGrupo },
    { label: 'Rede', value: summary.TmRede }
  ];

  if (isStore) {
    data = removeFromArray(data, 'label', 'Seu');
  }

  if (isStore && checkUserProfile(session, 'gerente')) {
    data = removeFromArray(data, 'label', 'Loja');
  }

  const chart = <ChartBarContainer data={data} formatMoney={true} />;

  return <Section title="Ticket Médio" content={chart} collapsable={true} />;
};

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(PanelTicketMedio);
