import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchCustomer, saveComment } from '../store/ducks/customers';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import Section from '../views/panels/Section';
import style from './comments.module.scss';
import CPFSearchPanel from '../views/forms/CPFSearchPanel';
import Button from '../views/forms/Button';
import { getUserIdFromCpf } from '../lib/utils';

const Comments = ({ customers, ...props }) => {
  const [cpf, setCPF] = useState();
  const [comment, setComment] = useState('');
  const [showCommentValidationError, setCommentValidationError] = useState(
    false
  );

  const searchClient = cpf => {
    props.searchCustomer(cpf);
  };

  const isSearching = userCpf => {
    const userId = getUserIdFromCpf(userCpf);
    return (
      customers && customers[userId] && customers[userId].status === 'loading'
    );
  };

  const didSearchCompleteWithNoResults = () => {
    const id = getUserIdFromCpf(cpf);

    if (!customers[id] || customers[id].status === 'loading') {
      return false;
    }

    return (
      customers &&
      customers[id] &&
      customers[id].status === 'loaded' &&
      customers[id].data &&
      !customers[id].data.Id &&
      !customers[id].data.Resumo
    );
  };

  const renderNoUserFound = () => (
    <p className={style.errorNotFound}>
      O CPF ou telefone informado ainda não está cadastrado
    </p>
  );

  const isValidComment = () => comment.length > 5;

  const saveComment = e => {
    e.preventDefault();
    if (!isValidComment()) {
      setCommentValidationError(true);
      return;
    }

    props.saveComment(getUserIdFromCpf(cpf), comment);
  };

  const onChangeComment = e => {
    if (showCommentValidationError && isValidComment()) {
      setCommentValidationError(false);
    }
    setComment(e.currentTarget.value);
  };

  const renderUserTextarea = () => {
    const id = getUserIdFromCpf(cpf);
    const userExists =
      customers && customers[id] && customers[id].data && customers[id].data.Id;

    if (userExists) {
      console.log(customers[id].data);
      return (
        <form onSubmit={saveComment}>
          <dl className={style.panel}>
            <dt>Cliente:</dt>
            <dd>{customers[id].data.Nome}</dd>
          </dl>
          <textarea
            className={style.textarea}
            placeholder="Escreva sua anotação"
            value={comment}
            onChange={onChangeComment}
          />
          {showCommentValidationError && (
            <div className={style.error}>
              Anotação não preenchida corretamente
            </div>
          )}
          <Button
            loading={customers[id].savingComment}
            className={style.button}
          >
            Salvar anotação
          </Button>
        </form>
      );
    }
  };

  const renderWarnMeContent = () => {
    return (
      <CPFSearchPanel
        onChange={e => setCPF(e.currentTarget.value)}
        isLoading={isSearching(cpf)}
        value={cpf}
        onSubmit={() => searchClient(cpf)}
      >
        {didSearchCompleteWithNoResults() && renderNoUserFound()}
        {renderUserTextarea()}
      </CPFSearchPanel>
    );
  };

  useEffect(() => {
    setComment('');
    setCommentValidationError(false);
  }, [cpf]);

  useEffect(() => {
    if (cpf) {
      props.history.push('/dashboard');
    }
  }, [customers.lastCommentSaved]);

  return (
    <AuthManager accessLevel="vendedor">
      <PageInternal>
        <div className={style.container}>
          <Section
            title="Anotações"
            collapsable={false}
            content={renderWarnMeContent()}
          />
        </div>
      </PageInternal>
    </AuthManager>
  );
};

const mapDispatchToProps = {
  searchCustomer,
  saveComment
};

const mapStateToProps = ({ customers }) => ({
  customers
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Comments)
);
