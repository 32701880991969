import React from 'react';
import style from './MessageBadge2.module.scss';
import Icon from '../general/Icon';

const MessageBadge2 = props => {

  if (props.oferta == null) return null;
  if (props.oferta === "") return null;

  return (
    <div className={style.badgeContainer}>
      <div className={style.icon}>
        <Icon name="lamp" color="#ffffff" size="23px" />
      </div>
      <div className={style.badge}>
        <h3>Oferta!</h3>
        {props.oferta}
      </div>
    </div>
  );
};

export default MessageBadge2;
