import React from 'react';
import style from './PanelProductRegistration.module.scss';
import FormLayout from '../forms/FormLayout';
import TextInput from '../forms/TextInput';
import Button from '../forms/Button';
import Icon from '../general/Icon';
import SelectBox from '../forms/SelectBox';
import { connect } from 'react-redux';
import { groupBy } from '../../lib/utils';

const PanelProductRegistration = props => {
  const customer = props.customer;
  const productList = props.productList;
  const addNewProduct = props.addNewProduct;
  const searchProduct = props.searchProduct;
  const saveProducts = props.saveProducts;
  const updateProductVariables = props.updateProductVariables;

  const warnMe = props.warnme[customer.Id];

  const getProductVariable = (codigo, key) => {
    const products = props.products[codigo];

    if (!products || !products.data || !products.data.length) {
      return [];
    }
    return Object.keys(groupBy(products.data, key)).map(el => ({
      value: el,
      name: el
    }));
  };

  const renderProduct = (el, index) => {
    const products = props.products[el.RefBasica];
    const fields = [
      <div className={style.field}>
        <TextInput
          onChange={e => searchProduct(e, index)}
          placeholder="Código do Produto"
          value={el.RefBasica}
        />
      </div>,

      <div className={style.field}>
        <label>Cor</label>
        <SelectBox
          loading={products && products.loading}
          placeholder="Selecione a cor"
          items={getProductVariable(el.RefBasica, 'CorDescricao')}
          onChange={e => {
            updateProductVariables(e.target.value, index, 'CorDescricao');
          }}
        />
      </div>,

      <div className={style.field}>
        <label>Tamanho</label>
        <SelectBox
          loading={products && products.loading}
          placeholder="Selecione o tamanho"
          items={getProductVariable(el.RefBasica, 'Tamanho')}
          onChange={e =>
            updateProductVariables(e.target.value, index, 'Tamanho')
          }
        />
      </div>
    ];

    return (
      <div className={style.panel} key={`product_${index}`}>
        <FormLayout fields={fields} buttons={[]} />
      </div>
    );
  };

  return (
    <div className={style.productPanel}>
      <h2>{customer.Nome}</h2>

      <div className={style.products}>
        <h3>Cadastro de Produto</h3>

        {productList.map(renderProduct)}

        <div className={style.add} onClick={addNewProduct}>
          <Icon name="plusCircle" color="#1cd4c7" size="14px" />
          Adicionar mais um produto à lista
        </div>

        <Button
          loading={warnMe && warnMe.status === 'loading'}
          onClick={saveProducts}
        >
          Cadastrar
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ products, warnme }) => ({ products, warnme });
export default connect(mapStateToProps)(PanelProductRegistration);
