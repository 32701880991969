export default {
  translation: {
    loginOrCode: 'Usuário',
    password: 'Senha',
    forbidden: 'Você não pode executar essa ação',
    invalidCredentials: 'Usuário ou senhas inválidos',
    inCharge: 'O sistema está em processo de carga',
    outOfTime: 'Fora do horário estabelecido',
    unknownError: 'Ocorreu um erro desconhecido',
    unknownErrorLogin: 'Ops. Dados incorretos :(',
    contactsToMake: `Hoje você tem <1>{{contacts}} contato para fazer</1>`,
    contactsToMake_plural: `Hoje você tem <1>{{contacts}} contatos para fazer</1>`,

    yourScheduleToday: 'Sua agenda para hoje',

    month1_short: 'Jan',
    month2_short: 'Fev',
    month3_short: 'Mar',
    month4_short: 'Abr',
    month5_short: 'Mai',
    month6_short: 'Jun',
    month7_short: 'Jul',
    month8_short: 'Ago',
    month9_short: 'Set',
    month10_short: 'Out',
    month11_short: 'Nov',
    month12_short: 'Dez'
  }
};
