import React, { Component } from 'react';
import moment from 'moment';
import style from './PanelScheduleContact.module.scss';
import Button from '../forms/Button';
import DateInput from '../forms/DateInput';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scheduleContact } from '../../store/ducks/contacts';
import { Redirect } from 'react-router-dom';
import SelectBox from '../forms/SelectBox';

class PanelScheduleContact extends Component {
  state = { contact: {} };

  setDateValidationError = () =>
    this.setState({ error: 'O campo data não foi preenchido corretamente' });

  handleDateChange = date => {
    this.setState({
      contact: { date }
    });

    if (!this.validateDate(date)) {
      this.setDateValidationError();
    } else {
      this.setState({ error: undefined });
    }
  };

  onChangeSelectbox = e => {
    this.setState({ motivoInsucesso: e.currentTarget.value });
  };

  validateDate = date => {
    const vigencia = this.props.customer.Vigencia;
    if (!vigencia) return false;

    return (
      date &&
      date >= new Date() &&
      date >= new Date(vigencia.data.DT_VIGENCIA_INI) &&
      date <= new Date(vigencia.data.DT_VIGENCIA_FIM)
    );
  };

  scheduleContact = reagendar => {
    const date = this.state.contact.date;
    const idCustomer = this.props.customer.data.Resumo.Cd_Cliente;

    if (reagendar && !this.validateDate(date)) {
      this.setDateValidationError();
      return null;
    }

    this.props.scheduleContact(idCustomer, {
      idTipoContato: this.props.tipoContato,
      nextDate: reagendar ? date : null,
      regra: this.props.regra,
      vendedor: this.props.session.viewAs || this.props.session.user.VendedorId,
      reagendar,
      contatoRealizado: false,
      motivoInsucesso: this.state.motivoInsucesso
    });
  };

  returnToClient = () => {
      this.props.history.push(
          '/agendas'
       // Tiramos o retorno para o cliente
      //'/clientes/' + this.props.customer.data.Resumo.Cd_Cliente + '?agenda'
    );
  };

  getMotivosInsucessoItems = () => {
    const motivos = this.props.customers.MotivosInsucesso.data || [];
    return motivos.map(motivo => ({
      value: motivo.Id,
      name: motivo.MotivoInsucesso
    }));
  };

  renderSchedulementTitle = (iniDate, endDate) => {
    const today = moment().add(1, 'day');
    if (endDate < today) {
      return (
        <React.Fragment>
          O prazo para reagendamento deste contato expirou.
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        Você pode reagendar este contato entre:
        <div className={style.period}>
          <strong>{today.format('DD/MM/YYYY')}</strong> e{' '}
          <strong>{endDate.format('DD/MM/YYYY')}</strong>
        </div>
      </React.Fragment>
    );
  };

  renderSchedulementPanel = (iniDate, endDate, contact) => {
    const today = moment().add(1, 'day');
    if (today <= endDate) {
      return (
        <React.Fragment>
          <div className={style.schedule}>
            <DateInput
              error={this.state.error}
              value={this.state.contact.date}
              onChange={this.handleDateChange.bind(this)}
              startDate={today.toDate()}
              endDate={endDate.toDate()}
            />

            <Button
              loading={contact.status === 'loading'}
              onClick={this.scheduleContact.bind(this, true)}
            >
              Agendar
            </Button>
          </div>

          {this.state.error && (
            <div className={style.error}>{this.state.error}</div>
          )}
        </React.Fragment>
      );
    }
  };

  render() {

    console.log(this.props.regra);
    const customer = this.props.customer.data;
    const vigencia = this.props.customer.Vigencia.data;
    const customerId = customer.Resumo.Cd_Cliente;
    // Se for a agenda de contato avulso o não leva para a pesquisa novamente
    if(this.props.regra === 99999) 
    {
      return <Redirect to={'/pesquisa-cliente'} />;
    }

    if (!vigencia) {
      return null;
    }

    

    const iniDate = moment(vigencia.DT_VIGENCIA_INI);
    const endDate = moment(vigencia.DT_VIGENCIA_FIM);
    const contact = this.props.contacts[customerId] || {};

    if (contact.status === 'saved') {
      return <Redirect to={'/agendas?reload=true'} />;
    }

    return (
      <div className={style.panel}>
        <div className={style.motivos}>
          <SelectBox
            placeholder="MOTIVO"
            onChange={this.onChangeSelectbox}
            loading={this.props.customers.MotivosInsucesso.status === 'loading'}
            items={this.getMotivosInsucessoItems()}
          />
        </div>
        {this.renderSchedulementTitle(iniDate, endDate)}
        <div className={style.form}>
          {this.renderSchedulementPanel(iniDate, endDate, contact)}
          <footer className={style.footer}>
            <Button
              loading={contact.status === 'loading'}
              onClick={this.scheduleContact.bind(this, false)}
            >
              Não Agendar
            </Button>
                    <Button
                        loading={contact.status === 'loading'}
                        onClick={this.returnToClient.bind(this)}>
              Ligar mais tarde
            </Button>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contacts, session, customers }) => ({
  contacts,
  session,
  customers
});

const mapDispatchToProps = { scheduleContact };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PanelScheduleContact)
);
