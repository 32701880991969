import types from './types';

export function verify() {
  return dispatch =>
    dispatch({
      type: 'API',
      meta: {
        url: '/usuarios/verificaIP',
        key: 'verified',
        action: types.PING_VERIFICATION
      }
    });
}

export function grantAccess(credentials) {
  return dispatch =>
    dispatch({
      type: 'API',
      meta: {
        url: '/usuarios/LiberaIP',
        params: credentials,
        method: 'POST',
        action: types.GRANT_ACCESS,
        key: 'granted'
      }
    });
}
