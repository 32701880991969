import React, { useEffect, useState } from "react";
import Accordion from "../panels/Accordion";
import InfoListItem from "../lists/InfoListItem";
import PropTypes from "prop-types";

const PanelPersonalInfo = ({ customer }) => {

  // SPOTUNIQUE ===================================================================================

  const [catalogs, setCatalogs] = useState(undefined);
  useEffect(() => {
    try {

      const phone = customer.Resumo.Tx_Cliente_TelPref;
      
      const unique = customer.unique ? customer.unique.unique : undefined;
      const mobile = customer.unique ? customer.unique.mobile : undefined;

      if (unique !== null) {
        console.log(unique);
        const catalogs = [];
        unique.forEach((each) => {
          console.log(each);
          catalogs.push(
              <div
                onClick={() => {
                  window.open(
                    `https://wa.me/55${phone}?text=${encodeURI(`${each.url}-550${mobile}`).replaceAll('+', '%2B')}`
                  );
                }}
                style={{ cursor: 'pointer', display: 'block', margin: '30px 0' }}
              >
                {each.title}<br/>
                <small style={{ fontSize:'13px', color:'#888' }}>{each.description}</small>
              </div>
          );
        });
        setCatalogs(catalogs);
      }
    } catch (err) {}
  }, []);

  // SPOTUNIQUE ===================================================================================

  return (
    <>
      {catalogs && (
        <div>
          <Accordion title="Catálogos">
            {catalogs}
          </Accordion>
        </div>
      )}
      <div>
        <Accordion title="Preferências do Cliente">
          <InfoListItem title="Segmento">
            {customer.Resumo.Tx_Cliente_Segmento}
          </InfoListItem>

          <InfoListItem title="Loja de Preferência">
            {customer.Resumo.Tx_Cliente_Loja_Pref_Nome}
          </InfoListItem>

          {customer.Resumo.CLIENTE_LOJA_PREF_ORIG && (
            <InfoListItem title="Filial de Preferência">
              {customer.Resumo.CLIENTE_LOJA_PREF_ORIG}
            </InfoListItem>
          )}

          <InfoListItem title="Vendedor de Preferência">
            {customer.Resumo.Tx_Cliente_Vendedor_Pref}
          </InfoListItem>

          <InfoListItem title="Cor de Preferência">
            {customer.Resumo.Tx_Cor_Pref}
          </InfoListItem>

          <InfoListItem title="Categoria de Preferência">
            {customer.Resumo.Tx_Cliente_Linha_Pref}
          </InfoListItem>

          <InfoListItem title="Tamanho">
            {customer.Resumo.Tx_Tamanho_Pref}
          </InfoListItem>
        </Accordion>
      </div>
    </>
  );
};

PanelPersonalInfo.propTypes = {
  customer: PropTypes.object,
};

export default PanelPersonalInfo;
