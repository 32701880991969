import React from "react";
import style from "./Loading.module.scss";
import Icon from "./Icon";

const Loading = props => {
  const showText = props.showText !== undefined ? props.showText : true;
  return (
    <div className={style.spin}>
      <Icon name="loading" color={props.color || "#ffffff"} size="18px" />
      {showText && <span>Carregando...</span>}
    </div>
  );
};

export default Loading;
