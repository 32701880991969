import React from "react";
import Accordion from "../panels/Accordion";
import Purchase from "../lists/Purchase";

const PanelPurchaseHistory = ({ customer }) => {
  if (!customer.HistoricoCompras) return null;

  return (
    <div>
      <Accordion title="Histórico de Compras">
        {customer.HistoricoCompras.map(el => (
          <Purchase purchase={el} />
        ))}
      </Accordion>
    </div>
  );
};

export default PanelPurchaseHistory;
