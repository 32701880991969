export default {
  SAVE: 'SAVE_WARNME',
  SAVING: 'SAVE_WARNME/LOADING',
  SAVED: 'SAVE_WARNME/SUCCESS',
  ERROR: 'SAVE_WARNME/ERROR',

  SAVE_PRODUCTS: 'WARNME/PRODUCTS',
  SAVING_PRODUCTS: 'WARNME/PRODUCTS/LOADING',
  PRODUCTS_SAVED: 'WARNME/PRODUCTS/SUCCESS',

  RESET: 'WARNME/RESET'
};
