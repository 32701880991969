import React from 'react';
import PageTitle from '../panels/PageTitle';

const Greetings = props => {
  const user = props.user;

  if (user.Perfil === 1) {
    return (
      <PageTitle>
        Olá, <strong>{user.Nome}</strong>,<br />
        Bem vindo ao Guru de Vendas.
      </PageTitle>
    );
  }

  if (user.Perfil >= 2 && props.isManagerViewingStoreInfo) {
    return (
      <PageTitle>
        Olá, <strong>{user.Nome}</strong>
      </PageTitle>
    );
  }

  // Vendedor passado por parâmetro
  return (
    <PageTitle>
      Vendedor(a): <strong>{props.seller.summary.data.NomeVendedor}</strong>
    </PageTitle>
  );
};

export default Greetings;
