import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { hasPermission } from "../../lib/auth";

function AuthManager(props) {
  const accessLevel = {
    public: 0,
    vendedor: 1,
    gerente: 2,
    admin: 3
  };

  if (
    props.accessLevel &&
    !hasPermission(props.session.user, accessLevel[props.accessLevel])
  ) {
    return <Redirect to="/" />;
  }

  if (props.only) {
    let level = 0;
    const requiredLevels = props.only.map(el => accessLevel[el]);

    if (props && props.session && props.session.user) {
      level = props.session.user.Perfil;
    }

    if (!requiredLevels.includes(level)) {
      return <Redirect to="/" />;
    }
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

AuthManager.propTypes = {
  accessLevel: PropTypes.oneOf(["public", "vendedor", "gerente", "admin"])
};

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(AuthManager);
