const jwtDecoder = require('jwt-decode');

export const decodeJwtToken = (token) => jwtDecoder(token)

export const isAuthenticated = (user) => {
  return user && user.Token && decodeJwtToken(user.Token);
}

export const hasPermission = (user, levelRequired) => {
  return user && user.Perfil && user.Perfil >= levelRequired;
}