/* eslint-disable no-useless-escape */
export function handleInputChange(container, event) {
  const obj = { ...this.state[container] };
  obj[event.target.name] = event.target.value;
  this.setState({ [container]: obj });
}

export function formatCurrency(value) {
  const safeValue = value ? value : 0;
  return safeValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
}

export const removeFromArray = (obj, key, testValue) => {
  return obj.filter(el => el[key] !== testValue);
};

export const checkUserProfile = (session, profile) => {
  const profileMap = { vendedor: 1, gerente: 2, supervisor: 3 };
  return (
    session &&
    session.user &&
    session.user.Perfil &&
    session.user.Perfil === profileMap[profile]
  );
};

export function getTenant(settings) {
  if (settings && settings.tenant) {
    return settings.tenant;
  }

  if (!window) {
    return '';
  }

  const urlParts = window.location.host.split('.');
  if (urlParts.length > 1) {
    return urlParts[0];
  }

  return 'demo';
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const reduceBy = (array, key) =>
  Object.values(
    array.reduce((output, value) => {
      if (!output.hasOwnProperty(value[key])) {
        output[value[key]] = value;
      }
      return output;
    }, {})
  );

// Faz o parse de uma queryString. Não funciona com valores duplicados ou arrays
export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
}

// Validar um CPF
export function validarCpf(strCPF) {
  if (strCPF === null) return false;

  strCPF = strCPF.replace('-', '');
  strCPF = strCPF.replace('.', '');
  strCPF = strCPF.replace('.', '');

  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function formatPercentage(value) {
  const safeValue = value ? value : 0;
  return (safeValue * 100).toFixed(2).replace('.', ',') + '%';
}

export function formatCPFCNPJ(valor) {
  valor = valor.replace(/\D/g, '');

  if (valor.length <= 14) {
    //CPF
    //Coloca um ponto entre o terceiro e o quarto dígitos
    valor = valor.replace(/(\d{3})(\d)/, '$1.$2');
    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    valor = valor.replace(/(\d{3})(\d)/, '$1.$2');
    //Coloca um hífen entre o terceiro e o quarto dígitos
    valor = valor.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    //CNPJ
    //Coloca ponto entre o segundo e o terceiro dígitos
    valor = valor.replace(/^(\d{2})(\d)/, '$1.$2');
    //Coloca ponto entre o quinto e o sexto dígitos
    valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    //Coloca uma barra entre o oitavo e o nono dígitos
    valor = valor.replace(/\.(\d{3})(\d)/, '.$1/$2');
    //Coloca um hífen depois do bloco de quatro dígitos
    valor = valor.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return valor;
}

export function getGrupoLabel(user, isStore, isStoreGroup) {
  if (isStore) {
    if (isStoreGroup) {
      return 'Grupo de Lojas';
    }

    if (user.Perfil === 2) {
      return 'Grupo de Vendedores';
    }

    return 'Grupo de Lojas';
  }

  return 'Grupo';
}

export const getUserIdFromCpf = cpf => cpf && cpf.replace(/[\-\.]/g, '');
