import React, { Component } from 'react';
import TextInput from '../views/forms/TextInput';
import { handleInputChange } from '../lib/utils';
import Section from '../views/panels/Section';
import FormLayout from '../views/forms/FormLayout';
import style from './resetPassword.module.scss';
import Button from '../views/forms/Button';
import { connect } from 'react-redux';
import { updateUserInfo } from '../store/ducks/session';
import { Redirect } from 'react-router-dom';
import ErrorPanel from '../views/warnings/ErrorPanel';

export class ResetPassword extends Component {
  state = {
    user: {}
  };

  get isLoading() {
    const session = this.props.session;
    return session && session.userUpdate === 'loading';
  }

  get hasError() {
    const session = this.props.session;
    return session && session.userUpdate === 'error';
  }

  get isSaved() {
    const session = this.props.session;
    return session && session.userUpdate === 'success';
  }

  componentDidMount() {
    if (this.props.session && this.props.session.user) {
      this.setState({
        user: {
          Nome: this.props.session.user.Nome
        }
      });
    }
  }

  savePassword() {
    const currentUserInfo = this.props.session.user;
    this.props.updateUserInfo(currentUserInfo, this.state.user);
  }

  renderChangePasswordPanel() {
    const errorPanel = (
      <ErrorPanel errorMessage="Ocorreu um erro ao resetar a senha" />
    );

    const fields = [
      <TextInput
        id="nome"
        name="Nome"
        icon="user"
        value={this.state.user.Nome}
        onChange={handleInputChange.bind(this, 'user')}
        placeholder="Seu nome"
      />,
      <TextInput
        id="senha"
        name="Senha"
        type="password"
        icon="lock"
        value={this.state.user.Senha}
        onChange={handleInputChange.bind(this, 'user')}
        placeholder="NOVA Senha"
      />
    ];

    const buttons = [<Button loading={this.isLoading}>Trocar senha</Button>];

    if (this.isSaved) {
      return <Redirect to="/" />;
    }

    return (
      <div className={style.panel}>
        {this.hasError && errorPanel}
        <FormLayout
          onSubmit={() => this.savePassword()}
          fields={fields}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={style.pageContainer}>
        <Section
          title="Troca de Senha"
          expandable={false}
          content={this.renderChangePasswordPanel()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });
const mapDispatchToProps = { updateUserInfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
