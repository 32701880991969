import React from 'react';
import Table from '../lists/Table';
import Section from '../panels/Section';
import { formatPercentage, formatCurrency } from '../../lib/utils';

const PanelMetaMensalRealizadoLoja = ({ tableData }) => {
  if (!tableData || !Array.isArray(tableData)) {
    return null;
  }

  const tableHeaders = ['LOJA', 'VENDIDO', 'META', '%'];
  const tableRows = tableData.map(el => [
    el.NomeLoja,
    formatCurrency(el.ValorLoja),
    formatCurrency(el.ValorMeta),
    formatPercentage(el.ValorLoja / Math.max(1, el.ValorMeta))
  ]);

  const table = <Table rows={[tableHeaders, ...tableRows]} />;

  return (
    <Section
      title="Meta Mensal X Realizado Pela Loja"
      content={table}
      collapsable={true}
    />
  );
};

export default PanelMetaMensalRealizadoLoja;
