import React, { useEffect, useState } from "react";
import Section from "../panels/Section";
import style from "./Customer.module.scss";
import Loading from "../general/Loading";
import ContactPanel from "./ContactPanel";
import SingleContactPanel from "./SingleContactPanel";
import PropTypes from "prop-types";
import PanelPersonalInfo from "./PanelPersonalInfo";
import PanelDesiredProducts from "./PanelDesiredProducts.js";
import PanelVisitedProducts from "./PanelVisitedProducts.js";
import PanelWarnMe from "./PanelWarnMe.js";
import PanelPurchaseProfile from "./PanelPurchaseProfile";
import PanelLastPurchase from "./PanelLastPurchase";
import PanelPurchaseHistory from "./PanelPurchaseHistory";
import PanelLoyaltyProgram from "./PanelLoyaltyProgram";
import PanelLastContacts from "./PanelLastContacts";
import PanelComments from "./PanelComments";
import CustomerHeaderName from "./CustomerHeaderName";
import MessageBadge from "../badges/MessageBadge";
import MessageBadge2 from "../badges/MessageBadge2";
import CustomerSummary from "./CustomerSummary";

import axios from "axios";
import TextInput from "../../views/forms/TextInput";
import ActionButton from "../../views/general/ActionButton";

const Customer = (props) => {
  
  const parseCustomerData = (customer) => {
    if (customer.status === "loading") {
      return <Loading />;
    }

    if (customer.error) {
      // TODO: tratar o erro aqui
      return null;
    }

    const customerData = customer.data;
    const scheduleName =
      customerData.Motivos[0] && customerData.Motivos[0].TX_NOME_REGRA;

    // Se vem de uma agenda, mostramos o painel completo com as informacoes
    if (props.showContactInfo) {
      return (
        <Section
          title={scheduleName}
          returnUrl="/agendas"
          content={renderCustomerDataWithContactInfo(customerData)}
        />
      );
    } else { // Se vem de uma pesquisa de cliente, mostramos outro painel
      return (
        <Section
          title="Pesquisar Cliente" // Deixando fixo
          returnUrl="/pesquisa-cliente"
          content={renderCustomerData(customerData)}
        />
      );
    }
  };

  // Pega os motivos do contato (isso serve para qdo vem de uma agenda)
  const getReasonForContact = (customer) =>
    customer.Motivos && customer.Motivos.length > 0 && customer.Motivos[0];

  // Painel do cliente mostrado na pesquisa de cliente
  const renderCustomerData = (customer) => {
    return (
      <React.Fragment>
        <div className={style.header}>
          <CustomerHeaderName
            name={customer.Resumo.Tx_Cliente_Nome}
            returnUrl={props.returnUrl}
          />

          <div className={style.contactContainerFloating}>
            <SingleContactPanel customer={customer} />
          </div>

          
        </div>

        <div className={style.clientSummaryWrap}>
          <h2 className={style.subTitle}>Resumo do Cliente</h2>
          <CustomerSummary customer={customer} />
        </div>

        <div className={style.moreDetailsWrap}>
          {renderCustomerInfoPanels(customer)}
        </div>

        <footer className={style.footer}>
          <div className={style.footerCta}>Escolha a forma de contato</div>
          <div className={style.contactContainer}>
            <SingleContactPanel customer={customer} />
          </div>
        </footer>
      </React.Fragment>
    );
    
    
  };

  // Painel com as informações do cliente
  const renderCustomerInfoPanels = (customer) => (
    <div className={style.infos}>
      <PanelPersonalInfo customer={customer} />
      <PanelWarnMe customer={customer} />
      <PanelDesiredProducts customer={customer} />
      <PanelVisitedProducts customer={customer} />
      <PanelPurchaseProfile customer={customer} />
      <PanelLastPurchase customer={customer} />
      <PanelPurchaseHistory customer={customer} />
      <PanelLoyaltyProgram customer={customer} />
      <PanelLastContacts customer={customer} />
      <PanelComments customer={customer} />
    </div>
  );

  // Painel do cliente mostrado na agenda
  const renderCustomerDataWithContactInfo = (customer) => {
    const reasonForContact = getReasonForContact(customer);
    return (
      <React.Fragment>
        <div className={style.header}>
          <CustomerHeaderName
            name={customer.Resumo.Tx_Cliente_Nome}
            returnUrl={props.returnUrl}
          />

          <div className={style.contactContainerFloating}>
            <ContactPanel customer={customer} />
          </div>

          <div className={style.messageContainer}>
            <MessageBadge dica={reasonForContact.TX_DICA} />
          </div>

          <div className={style.messageContainer}>
            <MessageBadge2 oferta={reasonForContact.TX_OFERTA} />
          </div>

        </div>

        <div className={style.clientSummaryWrap}>
          <h2 className={style.subTitle}>Resumo do Cliente</h2>
          <CustomerSummary customer={customer} />
        </div>

        <div className={style.moreDetailsWrap}>
          {renderCustomerInfoPanels(customer)}
        </div>

        <footer className={style.footer}>
          <div className={style.footerCta}>Escolha a forma de contato</div>
          <div className={style.contactContainer}>
            <ContactPanel customer={customer} />
          </div>
        </footer>
      </React.Fragment>
    );
  };

  const customer = props.customer;

    // SPOTUNIQUE ======================================================================================================

    const phoneMask = (value) => {
      if (!value) return "";
      value = String(value);
      value = value.replace(/\D/g,'');
      value = value.replace(/(\d{2})(\d)/,"($1) $2");
      value = value.replace(/(\d)(\d{4})$/,"$1-$2");
      return value;
    }

    const api = process.env.REACT_APP_UNIQUE_URL;

    const storage = JSON.parse(window.localStorage.getItem('SP_STORE_V2'));

    const partner = storage.session.user.marca;
    const seller = storage.session.user.VendedorId;

    // =================================================================================================================

    const uniqueSession = window.sessionStorage.getItem('unique');

    useEffect(() => {
      if (uniqueSession === null) {
        axios.get(`${api}/legacy/catalog/${seller}?partner=${partner}`)
        .then(() => window.sessionStorage.setItem('unique', 'yes'))
        .catch(()=> window.sessionStorage.setItem('unique', 'no'))
        .finally(() =>  window.location.reload());
      }
    },[]);

    // =================================================================================================================

    const mobileSession = window.sessionStorage.getItem('mobile');
    const [mobile, setMobile] = useState();

    useEffect(() => {
      if (!mobileSession) {
        axios.get(`${api}/legacy/seller/${seller}?partner=${partner}`).then(({ data }) => {
          setMobile(data.mobile);
        }).catch(()=> {
          setMobile('');
        });
      }
    },[]);



    const [unique, setUnique] = useState(false);

    useEffect(() => {
      if (!storage || !storage.session || !storage.session.user || !storage.session.user || props.customer.status === 'loading') {
        return;
      }
      if (uniqueSession) {
        const id_customer = props.customer.data.Resumo.Cd_Cliente;
        axios.get(`${api}/legacy/catalog/${seller}?partner=${partner}&id_customer=${id_customer}`).then(({data}) => {
          customer.data.unique = data;
        }).finally(() => setUnique(true));
      }
    }, [props]);

    if (!mobileSession && uniqueSession === 'yes') {
      return <div className={style.container}>
        <label style={{ padding: '0 0 15px 0', display: 'block', fontSize: '14px' }}>Digite seu celular (DDD + Número)</label>
        <TextInput
          id="mobile"
          icon="phone"
          value={mobile === undefined ? 'Carregando...' : phoneMask(mobile)}
          maxLength={15}
          onChange={(e) => setMobile(String(e.target.value).replace(/\D/g,''))}
          placeholder="Digite seu celular (DDD + Número)"
        />
        <br/>
        <ActionButton onClick={() => {
          axios.put(`${api}/legacy/seller/${seller}?partner=${partner}`, { mobile }).then(() => {
            window.sessionStorage.setItem('mobile', mobile);
            window.location.reload();
          });
        }}>Salvar</ActionButton>
        </div>;
    }

    if (!unique || !uniqueSession) {
      return <div className={style.container}><Loading /></div>;
    }

    // SPOTUNIQUE ========================================================================================================

  return <div className={style.container}>{parseCustomerData(customer)}</div>;
};

Customer.propTypes = {
  customer: PropTypes.object,
  returnUrl: PropTypes.string,
  showContactInfo: PropTypes.bool,
};

export default Customer;
