/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import Section from '../views/panels/Section';
import InputButtonRow from '../views/forms/InputButtonRow';
import TextInput from '../views/forms/TextInput';
import { handleInputChange } from '../lib/utils';
import { searchCustomer } from '../store/ducks/customers';
import { Redirect } from 'react-router-dom';
import style from './search.module.scss';
import ErrorPanel from '../views/warnings/ErrorPanel';

class ClientSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        query: ''
      }
    };
  }

  searchClient(query) {
    this.props.searchCustomer(query);
  }

  getParsedQuery = query => query.replace(/[\-\.]/g, '');

  isLoading(query) {
    const parsedQuery = this.getParsedQuery(query);
    return (
      this.props.customers &&
      this.props.customers[parsedQuery] &&
      this.props.customers[parsedQuery].status === 'loading'
    );
  }

  isError(query) {
    const parsedQuery = this.getParsedQuery(query);
    const customer = this.props.customers && this.props.customers[parsedQuery];

    return (
      customer &&
      customer.status === 'loaded' &&
      (customer.error ||
        !customer.data ||
        (!customer.data.Resumo && !customer.data.Id))
    );
  }

  isUserLoaded(query) {
    const parsedQuery = this.getParsedQuery(query);
    const customers = this.props.customers && this.props.customers[parsedQuery];

    return (
      customers &&
      customers.status === 'loaded' &&
      customers.data &&
      customers.data.CPF
    );
  }

  getSectionContent() {
    const query = this.state.customer.query;
    const loading = this.isLoading(query);

    const input = (
      <div>
        <TextInput
          name="query"
          onChange={handleInputChange.bind(this, 'customer')}
          value={query}
          placeholder="Insira o CPF ou DDD+Celular"
        />
      </div>
    );

    return (
      <div>
        <InputButtonRow
          input={input}
          loading={loading}
          buttonText="OK"
          onSubmit={() => this.searchClient(this.state.customer.query)}
        />

        {this.isError(query) && (
          <div className={style.errorContent}>
            <ErrorPanel errorMessage="Usuário não encontrado" />
          </div>
        )}
      </div>
    );
  }

  render() {
    if (this.isUserLoaded(this.state.customer.query)) {
      const userId = this.getParsedQuery(this.state.customer.query);
      const cpf = this.props.customers[userId].data.CPF;
      return <Redirect to={`/clientes/${cpf}`} />;
    }

    return (
      <AuthManager accessLevel="vendedor">
        <PageInternal>
          <div className={style.container}>
            <Section
              title="Pesquisar Cliente"
              collapsable={false}
              content={this.getSectionContent()}
            />
          </div>
        </PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ customers }) => ({ customers });
const mapDispatchToProps = { searchCustomer };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientSearchContainer);
