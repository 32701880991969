import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../general/Icon';
import style from './SingleContactPanel.module.scss';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import PopupMessage from '../general/PopupMessage';
import ActionButton from '../general/ActionButton';
import { sendSMS } from '../../store/ducks/customers';
import { connect } from 'react-redux';
import Loading from '../general/Loading';

const SingleContactPanel = props => {
  const customer = props.customer;
  const reasons = customer && customer.Motivos && customer.Motivos[0];
  const [showSMSPopup, setSMSPopupStatus] = useState(false);

  let medias = [];
  let enabled = [];

  const getMediasFromCustomers = () => {
    /*
    if (!reasons) {
      return [];
    }
    return [
      reasons.FL_TELEFONE && 'phone',
      reasons.FL_WHATSAPP && 'whatsapp',
      reasons.FL_EMAIL && 'email',
      reasons.FL_SMS && 'comment'
    ].filter(el => el);
    */

    return [
      true && 'phone',
      true && 'whatsapp'
    ].filter(el => el);


  };

  const isMediaEnabled = media => {
    return enabled.includes(media);
  };


  const sendWhatsappMessage = () => {
    const phone = customer.Resumo.Tx_Cliente_TelPref;
    const text = encodeURI("");
    window.open(
      `https://wa.me/55${phone}?text=${text}`
    );
    window.setTimeout(() => redirectUser(customer, 3, reasons), 0);
  };

  const callPhone = () => {
    window.location.href = `tel://${customer.Resumo.Tx_Cliente_TelPref}`;
    window.setTimeout(() => redirectUser(customer, 1, reasons), 0);
  };

  const redirectUser = (customer, type, reasons) => {
    const clientId = customer.Resumo.Cd_Cliente;
    //const idRegra = reasons.CD_REGRA;
    const idRegra = 99999; // Fixando o ID pq este é específico do contato avulso
    props.history.push(`/contatoavulso/${clientId}/${type}/${idRegra}`);
  };

  const getSMSDeliveryStatus = () =>
    props.customerStore[customer.Resumo.Cd_Cliente] &&
    props.customerStore[customer.Resumo.Cd_Cliente].sms &&
    props.customerStore[customer.Resumo.Cd_Cliente].sms.status;

  const renderSMSPopup = (customer, reasons) => {
    const clientId = customer.Resumo.Cd_Cliente;
    const smsSendingStatus = getSMSDeliveryStatus();
    const isSending = smsSendingStatus === 'loading';
    const isError = smsSendingStatus === 'error';
    const isSuccess = smsSendingStatus === 'loaded';
    const loadingComponent = <Loading showText="Enviando" />;

    let content = (
      <div>
        <div className={style.smsConfirmationText}>{reasons.TX_SMS_TEXTO}</div>

        {!isSuccess && (
          <React.Fragment>
            <ActionButton onClick={() => !isSending && props.sendSMS(clientId)}>
              {isSending ? loadingComponent : 'Enviar SMS'}
            </ActionButton>
            <ActionButton
              className={style.cancelSMS}
              onClick={() => !isSending && setSMSPopupStatus(false)}
            >
              Cancelar
            </ActionButton>
          </React.Fragment>
        )}

        {isSuccess && (
          <div className={style.successMessage}>SMS Enviado com sucesso!</div>
        )}

        {isError && (
          <div className={style.error}>
            Ocorreu um erro ao enviar a mensagem
          </div>
        )}
      </div>
    );

    if (isSuccess) {
      setTimeout(() => {
        redirectUser(customer, 4, reasons);
      }, 1000);
    }

    return (
      <PopupMessage
        onDimiss={() => setSMSPopupStatus(false)}
        title="Confirme o Envio de SMS"
        content={content}
      />
    );
  };

  const makeContact = (media, isEnabled) => {
    // Desativando este teste porque contato avulso sempre pode ser feito
    //if (!isEnabled || !reasons) return false;

    switch (media) {
      case 'whatsapp':
        sendWhatsappMessage();
        break;

      case 'phone':
        callPhone();
        break;

      default:
        break;
    }
  };

  const renderItem = (media, customer) => {
    const isEnabled = isMediaEnabled(media);
    const onClick = makeContact.bind(null, media, isEnabled);
    const classes = classnames({
      [style.badge]: true,
      [style[media]]: true,
      [style.disabled]: !customer || !isEnabled
    });

    return (
      <li className={classes} onClick={onClick}>
        <Icon name={media} color="#ffffff" />
      </li>
    );
  };

  const render = () => {
    if (props.showOnly) {
      medias = props.showOnly;
    } else {
      medias = ['phone', 'whatsapp'];
      enabled = getMediasFromCustomers();
    }

    return (
      <div className={style.contactPanelContainer}>
        <ul className={style.listContainer}>
          {medias.map(media => renderItem(media, customer))}
        </ul>
        {showSMSPopup && renderSMSPopup(customer, reasons)}
      </div>
    );
  };

  return render();
};

SingleContactPanel.propTypes = {
  customer: PropTypes.object,
  showOnly: PropTypes.array
};

const mapDispatchToProps = { sendSMS };
const mapStateToProps = ({ customers }) => ({ customerStore: customers });

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SingleContactPanel)
);
