import React, { Component } from "react";
import style from "./TextInput.module.scss";
import Icon from "../general/Icon";
import Text from "../general/Text";
import InputMask from "react-input-mask";

class TextInput extends Component {
  renderNormalInput() {
    return (
      <input
        {...this.props}
        placeholder={Text(this.props.placeholder)}
        className={style.input}
      />
    );
  }

  renderMaskedInput() {
    return (
      <InputMask
        {...this.props}
        placeholderChar={""}
        placeholder={Text(this.props.placeholder)}
        className={style.input}
        maskChar={null}
      />
    );
  }

  render() {
    const icon = this.props.icon;
    const classes = [style.inputContainer, icon && style.withIcon];

    return (
      <div className={classes.join(" ")}>
        {icon && <Icon name={icon} size="16px" />}
        {this.props.mask ? this.renderMaskedInput() : this.renderNormalInput()}
      </div>
    );
  }
}

export default TextInput;
