import React from "react";
import moment from "moment";
import { formatCPFCNPJ } from "../../lib/utils";

import style from "./CustomerSummary.module.scss";

const CustomerSummary = ({ customer, displayName = false }) => {
  const renderName = () => {
    return (
      <React.Fragment>
        <dt className={style.definitionListTitle}>Nome</dt>
        <dd className={style.definitionDescription}>
          {customer.Resumo.Tx_Cliente_Nome}
        </dd>
      </React.Fragment>
    );
  };

  return (
    <dl className={style.definitionList}>
      {displayName && renderName()}

      {customer.Resumo.Tx_Cliente_Tel && (
        <>
          <dt className={style.definitionListTitle}>Telefone</dt>
          <dd className={style.definitionDescription}>
            ({customer.Resumo.Tx_Cliente_Tel_Ddd}){" "}
            {customer.Resumo.Tx_Cliente_Tel}
          </dd>
        </>
      )}

      {customer.Resumo.Tx_Cliente_Cel && (
        <>
          <dt className={style.definitionListTitle}>Celular</dt>
          <dd className={style.definitionDescription}>
            ({customer.Resumo.Tx_Cliente_Cel_Ddd}) {' '}
            {customer.Resumo.Tx_Cliente_Cel}
          </dd>
        </>
      )}

      <dt className={style.definitionListTitle}>Data de Nascimento</dt>
      <dd className={style.definitionDescription}>
        {moment(customer.Resumo.Dt_Cliente_Dt_Nasc).format("DD/MM/YYYY")}
      </dd>

      <dt className={style.definitionListTitle}>CPF</dt>
      <dd className={style.definitionDescription}>
        {formatCPFCNPJ(customer.Resumo.Cd_Cliente)}
      </dd>
    </dl>
  );
};

export default CustomerSummary;
