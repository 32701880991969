import React from 'react';
import ChartBarContainer from '../charts/ChartBarContainer';
import Section from '../panels/Section';
import {
  checkUserProfile,
  removeFromArray,
  getGrupoLabel
} from '../../lib/utils';
import { connect } from 'react-redux';

const PanelProdutoPorAtendimento = ({
  summary,
  isStore,
  isStoreGroup,
  session
}) => {
  const groupLabel = getGrupoLabel(session.user, isStore, isStoreGroup);
  let data = [
    { label: 'Seu', value: summary.PaVendedor },
    { label: 'Loja', value: summary.PaLoja },
    { label: groupLabel, value: summary.ProdutosPorAtendimentoGrupo },
    { label: 'Rede', value: summary.PaRede }
  ];

  if (isStore) {
    data = removeFromArray(data, 'label', 'Seu');
  }

  if (isStore && checkUserProfile(session, 'gerente')) {
    data = removeFromArray(data, 'label', 'Loja');
  }

  const chart = <ChartBarContainer data={data} />;
  return (
    <Section
      title="Produtos por Atendimento"
      content={chart}
      collapsable={true}
    />
  );
};

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(PanelProdutoPorAtendimento);
