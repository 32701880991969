export default {
  RESET: "CUSTOMER/RESET",

  LOAD_CUSTOMER: "CUSTOMER",
  CUSTOMER_LOADING: "CUSTOMER/LOADING",
  CUSTOMER_ERROR: "CUSTOMER/ERROR",
  CUSTOMER_UPDATE: "CUSTOMER/UPDATE",

  LOAD_MOTIVOS_INSUCESSO: "CUSTOMER/MOTIVOS_INSUCESSO",
  LOAD_MOTIVOS_INSUCESSO_LOADING: "CUSTOMER/MOTIVOS_INSUCESSO/LOADING",
  LOAD_MOTIVOS_INSUCESSO_UPDATE: "CUSTOMER/MOTIVOS_INSUCESSO/UPDATE",
  LOAD_MOTIVOS_INSUCESSO_ERROR: "CUSTOMER/MOTIVOS_INSUCESSO/ERROR",

  LOAD_VIGENCIA: "CUSTOMER/VIGENCIA",
  CUSTOMER_VIGENCIA_LOADING: "CUSTOMER/VIGENCIA/LOADING",
  CUSTOMER_VIGENCIA_UPDATE: "CUSTOMER/VIGENCIA/UPDATE",
  CUSTOMER_VIGENCIA_ERROR: "CUSTOMER/VIGENCIA/ERROR",

  SEND_SMS: "CUSTOMER/SMS",
  SEND_SMS_LOADING: "CUSTOMER/SMS/LOADING",
  SEND_SMS_SUCCESS: "CUSTOMER/SMS/SUCCESS",
  SEND_SMS_ERROR: "CUSTOMER/SMS/ERROR",

  SAVE_COMMENT: "CUSTOMER/COMMENT",
  SAVE_COMMENT_LOADING: "CUSTOMER/COMMENT/LOADING",
  SAVE_COMMENT_SUCCESS: "CUSTOMER/COMMENT/SUCCESS",
  SAVE_COMMENT_ERROR: "CUSTOMER/COMMENT/ERROR",
};
