import React from "react";
import PropTypes from "prop-types";
import style from "./ChartBar.module.scss";
import Transition from 'react-transition-group/Transition';
import { formatCurrency, formatPercentage } from "../../lib/utils";

const ChartBar = props => {
  let value;
  if (props.formatMoney) {
    value = formatCurrency(props.data.value ? props.data.value : "0");
  } else {
    value = props.data.value ? props.data.value.toFixed(2) : "0";
  }

  if (props.percentage) {
    value = formatPercentage(value);
  }

  const formattedValue = value.replace(".", ",");
  const percentage = props.data.percentage * 100 + "%";
  const animation = {
    entering: {width: 0, height: 100},
    entered: {width: percentage, height: percentage}
  };

  return (
    <Transition in={props.startAnimation} timeout={0}>
      {(state) => (
        <div style={{...animation[state]}} className={style.bar}>
          <div className={style.barTop}>
            <span>{formattedValue}</span>
          </div>
          <div className={style.label}>{props.data.label}</div>
        </div>
      )}
    </Transition>
  );
};

ChartBar.propTypes = {
  data: PropTypes.object
};

export default ChartBar;
