/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import { searchCustomer, resetCustomerData } from '../store/ducks/customers';
import {
  registerUser,
  registerItems,
  resetWarnMeData
} from '../store/ducks/warnme';
import { searchProduct } from '../store/ducks/products';
import style from './warnme.module.scss';
import Section from '../views/panels/Section';
import { Redirect } from 'react-router-dom';
import { handleInputChange, validarCpf, validateEmail } from '../lib/utils';
import PanelUserRegistration from '../views/warnMe/PanelUserRegistration';
import PanelProductRegistration from '../views/warnMe/PanelProductRegistration';
import CPFSearchPanel from '../views/forms/CPFSearchPanel';

class WarnMe extends Component {
  state = {
    customer: {
      cpf: '',
      nome: '',
      email: '',
      telefone: ''
    },
    products: [{ RefBasica: '' }],
    error: '',
    panel: ''
  };

  constructor(props) {
    super(props);
    props.resetWarnMeData();
  }

  validateCustomer() {
    const customer = this.state.customer;

    if (Object.keys(customer).filter(el => !customer[el]).length > 0) {
      this.setState({ error: 'Você precisa preencher todos os campos' });
      return false;
    }

    if (!validateEmail(customer.email)) {
      this.setState({ error: 'O e-mail informado não é válido' });
      return false;
    }

    if (!validarCpf(customer.cpf)) {
      return false;
    }

    this.setState({ error: '' });
    return true;
  }

  saveClient() {
    if (!this.validateCustomer()) {
      return;
    }

    this.props.registerUser(this.state.customer);
  }

  searchClient(cpf) {
    this.props.resetWarnMeData();
    this.props.resetCustomerData(cpf);
    this.props.searchCustomer(cpf);
  }

  addProducts() {
    const products = this.state.products;
    this.setState({
      products: products.concat({ RefBasica: '' })
    });
  }

  saveProducts() {
    const products = this.state.products;
    this.props.registerItems(this.state.customer, products);
  }

  doesUserExist(id) {
    const customers = this.props.customers;
    const warnMe = this.props.warnme;

    return (
      (customers &&
        customers[id] &&
        customers[id].data &&
        customers[id].data.Id) ||
      (warnMe && warnMe[id] && warnMe[id].client && warnMe[id].client.CPF)
    );
  }

  didSearchCompleteWithNoResults(id) {
    const customers = this.props.customers;
    return (
      customers &&
      customers[id] &&
      customers[id].status === 'loaded' &&
      customers[id].data &&
      !customers[id].data.Id
    );
  }

  isUserLoading(id) {
    const customers = this.props.customers;
    return customers && customers[id] && customers[id].status === 'loading';
  }

  isSearching(id) {
    return this.isUserLoading(id);
  }

  isUserSaving(id) {
    const customers = this.props.customers;
    return (
      this.isUserLoading(id) && customers[id].data && !customers[id].data.CPF
    );
  }

  isUserSaved(id) {
    const customers = this.props.customers;
    return customers && customers[id] && customers[id].status === 'saved';
  }

  isProductListSaved(id) {
    const customer = this.props.customers && this.props.customers[id];
    return (
      customer &&
      this.props.warnme &&
      this.props.warnme[id] &&
      this.props.warnme[id].status === 'saved'
    );
  }

  isProductListSaving(id) {
    const customer = this.props.customers && this.props.customers[id];

    return (
      customer &&
      this.props.warnme &&
      this.props.warnme[id] &&
      this.props.warnme[id].status === 'loading' &&
      this.props.warnme[id].data
    );
  }

  searchProduct(event, productId) {
    const productCode = event.target.value;
    this.setState(prevState => {
      const products = prevState.products;
      products[productId] = {
        ...products[productId],
        RefBasica: productCode
      };
      return { products };
    });

    this.props.searchProduct(productCode);
  }

  updateProductVariables(value, index, variable) {
    this.setState(prevState => {
      const products = prevState.products;
      products[index] = {
        ...products[index],
        [variable]: value
      };
      return { products };
    });
  }

  showUserRegistrationPanel(userId) {
    const condition =
      (this.didSearchCompleteWithNoResults(userId) ||
        this.isUserSaving(userId)) &&
      !this.doesUserExist(userId) &&
      !this.isProductListSaving(userId);

    return (
      condition && (
        <PanelUserRegistration
          customer={this.props.warnme[userId]}
          onChange={handleInputChange.bind(this, 'customer')}
          onSubmit={this.saveClient.bind(this)}
          error={this.error}
        />
      )
    );
  }

  showNoUserFoundPanel(userId) {
    const condition =
      (this.didSearchCompleteWithNoResults(userId) ||
        this.isUserSaving(userId)) &&
      !this.doesUserExist(userId) &&
      !this.isProductListSaving(userId);

    return (
      condition && (
        <p className={style.errorNotFound}>
          O CPF informado ainda não está cadastrado
        </p>
      )
    );
  }

  showProductRegistrationPanel(userId) {
    const condition = this.doesUserExist(userId);
    let customer;

    if (condition) {
      if (
        this.props.warnme[userId] &&
        this.props.warnme[userId].client &&
        this.props.warnme[userId].client.CPF
      ) {
        customer = this.props.warnme[userId].client;
      }

      if (
        this.props.customers[userId] &&
        this.props.customers[userId].data &&
        this.props.customers[userId].data.CPF
      ) {
        customer = this.props.customers[userId].data;
      }
    }

    return (
      condition && (
        <PanelProductRegistration
          productList={this.state.products}
          customer={customer}
          addNewProduct={this.addProducts.bind(this)}
          searchProduct={this.searchProduct.bind(this)}
          saveProducts={this.saveProducts.bind(this)}
          updateProductVariables={this.updateProductVariables.bind(this)}
        />
      )
    );
  }

  renderWarnMeContent() {
    const customerCpf = this.state.customer.cpf;
    const userId = customerCpf.replace(/[\-\.]/g, '');

    if (this.isProductListSaved(userId)) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <CPFSearchPanel
        onChange={handleInputChange.bind(this, 'customer')}
        value={this.state.customer.cpf}
        isLoading={this.isSearching(userId)}
        onSubmit={() => this.searchClient(this.state.customer.cpf)}
      >
        {this.showNoUserFoundPanel(userId)}
        {this.showProductRegistrationPanel(userId)}
      </CPFSearchPanel>
    );
  }

  render() {
    return (
      <AuthManager accessLevel="vendedor">
        <PageInternal>
          <div className={style.container}>
            <Section
              title="Registro de Avise-me"
              collapsable={false}
              content={this.renderWarnMeContent()}
            />
          </div>
        </PageInternal>
      </AuthManager>
    );
  }
}

const mapStateToProps = ({ session, customers, warnme }) => ({
  session,
  customers,
  warnme
});

const mapDispatchToProps = {
  searchCustomer,
  registerUser,
  searchProduct,
  registerItems,
  resetWarnMeData,
  resetCustomerData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarnMe);
