import React, { Component } from 'react';
import CustomerHeaderName from '../customers/CustomerHeaderName';
import Section from '../panels/Section';
import style from './ContactPage.module.scss';
import PanelScheduleContact from './PanelScheduleContact';
import PanelThermometer from './PanelThermometer';
import Loading from '../general/Loading';
import classnames from 'classnames';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotInTouch: undefined
    };
  }

  setContactStatus = status => {
    this.setState({ gotInTouch: status });
  };

  getCheckboxOption = (label, condition) => {
    const callback = this.setContactStatus;
    const classes = classnames(
      style.option,
      this.state.gotInTouch === condition && style.checked
    );

    return (
      <span className={classes} onClick={callback.bind(this, condition)}>
        {label}
      </span>
    );
  };

  getSectionContent = customer => {
    const clientName = customer.data.Resumo.Tx_Cliente_Nome;

    return (
      <React.Fragment>
        <div className={style.header}>
          <CustomerHeaderName name={clientName} />
        </div>

        <div className={style.status}>
          {this.getCheckboxOption('Não', 'no')}
          <span className={style.label}>Conseguiu contato?</span>
          {this.getCheckboxOption('Sim', 'yes')}
        </div>

        {this.state.gotInTouch === 'no' && (
          <PanelScheduleContact
            regra={this.props.regra}
            tipoContato={this.props.tipoContato}
            customer={customer}
          />
        )}

        {this.state.gotInTouch === 'yes' && (
          <PanelThermometer
            regra={this.props.regra}
            tipoContato={this.props.tipoContato}
            customer={customer}
          />
        )}
      </React.Fragment>
    );
  };

  getMainSection = customer => (
    <Section
      title="Voltar para página do cliente"
      returnUrl={`/clientes/${customer.data.Resumo.Cd_Cliente}?agenda`}
      collapsable={false}
      content={this.getSectionContent(customer)}
    />
  );

  render() {
    const customer = this.props.customer;
    const isLoading = customer.status === 'loading';
    return (
      <div className={style.container}>
        {isLoading ? <Loading /> : this.getMainSection(customer)}
      </div>
    );
  }
}

export default ContactPage;
