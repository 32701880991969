import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../panels/PageTitle";
import IconBadge from "../badges/IconBadge";
import I18n from "../enhancers/I18n";
import style from "./Schedule.module.scss";
import Section from "../panels/Section";
import PeopleListItem from "../lists/PeopleListItem";

const Schedule = props => {
  const getPanelTitle = (scheduleName, entries, maxEntries) => {
    if (entries.length > maxEntries) {
      return `${scheduleName} (${maxEntries}/${entries.length})`;
    } else {
      return `${scheduleName} (${entries.length}/${entries.length})`;
    }
  };

  const getMaxEntries = entries => entries[0].No_QTDExibicao;

  const getPeopleList = (entries, maxEntries) => {
    return entries.slice(0, maxEntries).map(el => <PeopleListItem data={el} />);
  };

  const getSchedulePanel = (scheduleName, entries) => {
    if (entries.length === 0) {
      return null;
    }

    const maxEntries = getMaxEntries(entries);
    const panelTitle = getPanelTitle(scheduleName, entries, maxEntries);
    const people = getPeopleList(entries, maxEntries);

    return <Section title={panelTitle} content={people} collapsable={true} />;
  };

  const getScheduleEntries = data =>
    Object.keys(data).map(scheduleName => {
      const schedule = data[scheduleName];
      return getSchedulePanel(scheduleName, schedule);
    });

  return (
    <React.Fragment>
      <PageTitle>
        <div className={style.title}>
          <IconBadge />
          <I18n>yourScheduleToday</I18n>
        </div>
      </PageTitle>
      <div className={style.container}>{getScheduleEntries(props.data)}</div>
    </React.Fragment>
  );
};

Schedule.propTypes = {
  data: PropTypes.object
};

export default Schedule;
