import React from 'react';
import PropTypes from 'prop-types';
import style from './LoadingPanel.module.scss';

const LoadingPanel = props => {
  return props.isLoading ? <div className={style.loadingPanel} /> : null;
};

LoadingPanel.propTypes = {
  isLoading: PropTypes.bool
};

export default LoadingPanel;
