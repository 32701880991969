import React, { Component } from 'react';
import style from './ActionButton.module.scss';

class ActionButton extends Component {
  render() {
    const { className, ...props } = this.props;
    const classList = [style.button, className];

    return (
      <button className={classList.join(' ')} {...props}>
        {this.props.children}
      </button>
    );
  }
}

export default ActionButton;
