import types from './types';

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  let currentSellerData = state[action.key] ? state[action.key] : {};
  let newSellerData;

  switch (action.type) {
    case types.SELLER_LOADING_SCHEDULE:
    case types.SELLER_UPDATE_SCHEDULE:
    case types.SELLER_ERROR_SCHEDULE:
      newSellerData = {
        ...currentSellerData,
        schedule: {
          status: action.status,
          ...action.payload
        }
      };
      break;

    case types.SELLER_LOADING_SUMMARY:
    case types.SELLER_UPDATE_SUMMARY:
    case types.SELLER_ERROR_SUMMARY:
      newSellerData = {
        ...currentSellerData,
        summary: {
          status: action.status,
          ...action.payload
        }
      };
      break;

    case types.LOADING_LIST:
      newSellerData = {
        ...currentSellerData,
        status: action.status,
        data: []
      };
      break;

    case types.UPDATE_LIST:
      newSellerData = {
        ...currentSellerData,
        status: action.status,
        ...action.payload
      };
      break;

    default:
      return state;
  }

  if (newSellerData) {
    return {
      ...state,
      [action.key]: newSellerData
    };
  } else {
    return state;
  }
};
