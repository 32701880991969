import React from 'react';
import Table from '../lists/Table';
import Section from '../panels/Section';
import { formatCurrency } from '../../lib/utils';

const PanelConversaoDeContatosEmVenda = ({ summary }) => {
  if (!summary || !summary.ResumosContatoAgenda) {
    return null;
  }

  const tableRows = summary.ResumosContatoAgenda.map(el => [
    el.tx_agenda,
    formatCurrency(el.no_valor_conversao)
  ]);

  const label = formatCurrency(
    summary.ResumosContatoAgenda.reduce(
      (total, el) => total + el.no_valor_conversao,
      0
    )
  );

  const sortedTableRows = tableRows.sort((a1, a2) => (a1[0] > a2[0] ? 1 : -1));

  const table = <Table rows={sortedTableRows} />;
  return (
    <Section
      title="Conversão de Contatos em Venda"
      content={table}
      label={label}
      collapsable={true}
    />
  );
};

export default PanelConversaoDeContatosEmVenda;
