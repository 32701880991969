import types from './types';

export default function(state = {}, action) {
  switch (action.type) {
    case types.PING_VERIFICATION_LOADING:
    case types.PING_VERIFICATION_SUCCESS:
    case types.PING_VERIFICATION_ERROR:
    case types.GRANT_ACCESS_LOADING:
      return {
        ...state,
        [action.key]: {
          status: action.status,
          ...action.payload
        }
      };

    case types.GRANT_ACCESS_ERROR:
      return {
        ...state,
        granted: {
          status: 'error',
          error: 'Os dados informados são inválidos'
        }
      };

    case types.GRANT_ACCESS_SUCCESS:
      return {
        verified: {},
        granted: {
          status: 'loaded',
          data: true
        }
      };

    default:
      return state;
  }
}
