import React from "react";
import style from "./login.module.scss";
import {Link} from 'react-router-dom';
import logo from "../static/assets/sp-logo-big.png";
import PageCentered from "../views/layouts/PageCentered";
import ErrorPanel from "../views/warnings/ErrorPanel";
import ActionButton from "../views/general/ActionButton";

class AccessGrantSuccess extends React.Component {
  render() {
    const message = "O sistema já está liberado para ser usado! Obrigado.";
    return (
      <PageCentered>
        <i className={style.logo}>
          <img alt="" src={logo}/>
        </i>
        <ErrorPanel errorMessage={message}/>
        <Link to="/">
          <ActionButton>Acessar Sistema</ActionButton>
        </Link>
      </PageCentered>
    );
  }
}

export default AccessGrantSuccess;
