export default {
  LOAD_LIST: 'SELLER/LIST',
  LOADING_LIST: "SELLER/LIST/LOADING",
  UPDATE_LIST: "SELLER/LIST/UPDATE",

  LOAD_SUMMARY: 'SELLER/SUMMARY',
  SELLER_LOADING_SUMMARY: "SELLER/SUMMARY/LOADING",
  SELLER_UPDATE_SUMMARY: "SELLER/SUMMARY/UPDATE",
  SELLER_ERROR_SUMMARY: "SELLER/SUMMARY/ERROR",

  LOAD_SCHEDULE: 'SELLER/SCHEDULE',
  SELLER_LOADING_SCHEDULE: "SELLER/SCHEDULE/LOADING",
  SELLER_UPDATE_SCHEDULE: "SELLER/SCHEDULE/UPDATE",
  SELLER_ERROR_SCHEDULE: "SELLER/SCHEDULE/ERROR"
};
