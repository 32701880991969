import React from "react";
import PropTypes from "prop-types";
import style from "./Table.module.scss";

const Table = props => {
  const header = props.header && (
    <tr className={style.header}>
      {props.header.map(el => (
        <td>{el}</td>
      ))}
    </tr>
  );

  const rows = props.rows.map(row => (
    <tr>
      {row.map(el => (
        <td>{el}</td>
      ))}
    </tr>
  ));

  return (
    <table className={style.table}>
      <thead>{header}</thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

Table.propTypes = {
  header: PropTypes.array,
  rows: PropTypes.array
};

export default Table;
