import React from 'react';
import PropTypes from 'prop-types';
import style from './PopupMessage.module.scss';

const PopupMessage = props => {
  return (
    <div className={style.overlay} onClick={props.onDimiss}>
      <div className={style.popup} onClick={e => e.stopPropagation()}>
        <h2 className={style.title}>{props.title}</h2>
        <div className={style.content}>{props.content}</div>
      </div>
    </div>
  );
};

PopupMessage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onDimiss: PropTypes.func
};

export default PopupMessage;
