import types from './types';
import { decodeJwtToken } from '../../../lib/auth';

export default (state = {}, action) => {
  switch (action.type) {
    case types.USER_FETCH:
      return {};

    case types.USER_LOGIN_LOADING:
      return { ...state, status: action.status };

    case types.USER_LOGIN_SUCCESSFUL:
      return {
        status: action.status,
        user: {
          ...action.payload.data,
          ...decodeJwtToken(action.payload.data.Token)
        }
      };

    case types.USER_LOGIN_ERROR:
      let error = '';
      if (!action.payload.error) {
        return { status: action.status };
      }

      switch (action.payload.error.status) {
        case 403:
          error = 'forbidden';
          break;

        case 404:
          error = 'invalidCredentials';
          break;

        case 406:
          error = 'outOfTime';
          break;

        case 410:
          error = 'outOfTime';
          break;

        default:
          error = 'unknownError';
          break;
      }

      return { status: action.status, error };

    case types.USER_LOGOUT:
      return {};

    case types.SET_CURRENT_VIEW:
      return {
        ...state,
        viewAs: action.payload
      };

    case types.USER_UPDATE_LOADING:
      return {
        ...state,
        userUpdate: 'loading'
      };

    case types.USER_UPDATE_ERROR:
      return {
        ...state,
        userUpdate: 'error'
      };

    case types.USER_UPDATE_SUCCESS:
      const user = state.user;
      if (user) {
        user.TrocaSenha = false;
      }
      return {
        ...state,
        user,
        userUpdate: 'success'
      };

    default:
      return state;
  }
};
