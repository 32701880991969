import types from './types';
import { extendState } from '../../../lib/redux-utils';
import { reduceBy } from '../../../lib/utils';

const initialState = {
  resumo: {},
  overview: {},
  schedule: {},
  list: { status: '', data: [] }
};

const reduceStoreList = storeList => reduceBy(storeList, 'Id');

export default function(state = initialState, action) {
  switch (action.type) {
    // Lista de Lojas
    case types.LOADING_LIST:
    case types.ERROR_LIST:
      return extendState(state, 'list', null, action.status, action.payload);

    case types.UPDATE_LIST:
      const data = action.payload.data || [];
      return extendState(state, 'list', null, action.status, {
        data: reduceStoreList(data)
      });

    // Resumo Todas as Lojas
    case types.LOADING_OVERVIEW:
    case types.UPDATE_OVERVIEW:
    case types.ERROR_OVERVIEW:
      return extendState(
        state,
        'overview',
        action.key,
        action.status,
        action.payload
      );

    case types.LOADING_RESUMO:
    case types.UPDATE_RESUMO:
    case types.ERROR_RESUMO:
      return extendState(
        state,
        'resumos',
        action.key,
        action.status,
        action.payload
      );

    // Agenda
    case types.LOADING_SCHEDULE:
    case types.UPDATE_SCHEDULE:
    case types.ERROR_SCHEDULE:
      return extendState(
        state,
        'schedules',
        action.key,
        action.status,
        action.payload
      );

    default:
      return state;
  }
}
