import types from './types';

export const loadResumo = (sellerId, force = false) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!force && state.sellers[sellerId] && state.sellers[sellerId].summary) {
      return null;
    }
    dispatch({
      type: 'API',
      meta: {
        url: `/vendedores/${sellerId}/resumo?vendedor=${sellerId}&loja=`,
        action: types.LOAD_SUMMARY,
        key: sellerId
      }
    });
  };
};

export const loadSchedule = (sellerId, force = false) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!force && state.sellers[sellerId] && state.sellers[sellerId].schedule) {
      return null;
    }
    dispatch({
      type: 'API',
      meta: {
          url: `/vendedores/agendas?id=${sellerId}&loja=&timestamp=${Date.now()}`,
        action: types.LOAD_SCHEDULE,
        key: sellerId
      }
    });
  };
};

export const loadSellersByManager = managerId => dispatch => {
  dispatch({
    type: 'API',
    meta: {
      url: `/vendedores/${managerId}/vendedores`,
      action: types.LOAD_LIST,
      key: 'list'
    }
  });
};

export const loadSellersByStore = storeId => dispatch => {
  if (!storeId) {
    dispatch({
      type: types.UPDATE_LIST,
      status: 'loaded',
      key: 'list',
      payload: { data: [] }
    });
    return;
  }

  dispatch({
    type: 'API',
    meta: {
      url: `/lojas/${storeId}/vendedores`,
      action: types.LOAD_LIST,
      key: 'list'
    }
  });
};
