export const extendState = (state, key, resourceId, status, payload) => {
  if (resourceId) {
    return {
      ...state,
      [key]: {
        ...state[key],
        [resourceId]: { status, ...payload }
      }
    };
  } else {
    return {
      ...state,
      [key]: {
        status: status,
        ...payload
      }
    };
  }
};
