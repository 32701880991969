import React from "react";
import InfoListItem from "../lists/InfoListItem";
import Accordion from "../panels/Accordion";
import moment from "moment";

const PanelLoyaltyProgram = ({ customer }) => {
  const getLoyaltyProgram = customer =>
        customer.PontosExpirar &&
        customer.PontosExpirar.length > 0 &&
        customer.PontosExpirar[0];
  
        const LoyaltyProgram = getLoyaltyProgram(customer);
  if(!LoyaltyProgram) return null;
  
  //console.log(customer);
  
  return (
    <div>
      <Accordion title="Fidelidade / Bonus / Pontos">
        <InfoListItem title="Data próxima Expiração">
          {moment(customer.PontosExpirar[0].SALDO_DATA_EXPIRACAO).format("DD/MM/YYYY")}
        </InfoListItem>

        <InfoListItem title="Pontos a Expirar">
        {customer.PontosExpirar[0].SALDO_PONTO}
        </InfoListItem>

        
      </Accordion>
    </div>
  );
};

export default PanelLoyaltyProgram;
