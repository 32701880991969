import React, { Component } from "react";
import style from "./PageCentered.module.scss";

export class PageCentered extends Component {
  render() {
    return <div className={style.container}>{this.props.children}</div>;
  }
}

export default PageCentered;
