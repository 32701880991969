import React from 'react';
import Section from '../panels/Section';
import ChartBarContainer from '../charts/ChartBarContainer';
import {
  checkUserProfile,
  removeFromArray,
  getGrupoLabel
} from '../../lib/utils';
import { connect } from 'react-redux';

const PanelQuantidadeDeAtendimentos = ({
  summary,
  isStore,
  isStoreGroup,
  session
}) => {
  const groupLabel = getGrupoLabel(session.user, isStore, isStoreGroup);
  let data = [
    { label: 'Seu', value: summary.QAVendedor },
    { label: 'Loja', value: summary.QALoja },
    { label: groupLabel, value: summary.QuantidadeAtendimentosGrupo },
    { label: 'Rede', value: summary.QARede }
  ];

  if (isStore) {
    data = removeFromArray(data, 'label', 'Seu');
  }

  if (isStore && checkUserProfile(session, 'gerente')) {
    data = removeFromArray(data, 'label', 'Loja');
  }

  const chart = <ChartBarContainer data={data} />;
  return (
    <Section
      title="Quantidade de Vendas"
      content={chart}
      collapsable={true}
    />
  );
};

const mapStateToProps = ({ session }) => ({ session });
export default connect(mapStateToProps)(PanelQuantidadeDeAtendimentos);
