/* eslint-disable no-shadow-restricted-names */
import types from "./types";

export default function(state = {}, action) {
  switch (action.type) {
    case types.SEARCH_PRODUCT:
      return {
        ...state,
        [action.productCode]: {
          loading: true
        }
      };

    case types.UPDATE_PRODUCT:
      return {
        ...state,
        [action.productCode]: {
          loading: false,
          productCode: action.productCode,
          data: action.payload
        }
      };

    case types.PRODUCT_NOT_FOUND:
      const { [action.productCode]: undefined, ...newState } = state;
      return newState;

    default:
      return state;
  }
}
