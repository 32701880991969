import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../store/ducks/session';
import style from './login.module.scss';
import { Link } from 'react-router-dom';
import logo from '../static/assets/sp-logo-big.png';
import PageCentered from '../views/layouts/PageCentered';
import ErrorPanel from '../views/warnings/ErrorPanel';
import ActionButton from '../views/general/ActionButton';

class OutsideBusinessHours extends React.Component {
  componentDidMount() {
    this.props.logoutUser();
  }

  render() {
    const message = 'O sistema está fora do horário permitido para acesso';
    return (
      <PageCentered>
        <i className={style.logo}>
          <img alt="" src={logo} />
        </i>
        <ErrorPanel errorMessage={message} />
        <Link to="/">
          <ActionButton>Voltar para o login</ActionButton>
        </Link>
      </PageCentered>
    );
  }
}

export default connect(
  null,
  { logoutUser }
)(OutsideBusinessHours);
