import types from "./types";

const initialState = {
  fields: {},
  prospects: {},
  negotiations: {},
  configs: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.PROSPECT_RESET_INSERT:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          new: {},
        },
        negotiations: {
          ...state.negotiations,
          new: {},
        },
      };

    // Carregamento da listagem de campos
    case types.LOAD_FIELDS_LOADING:
      return {
        ...state,
        fields: {
          isLoading: true,
        },
      };

    case types.LOAD_FIELDS_SUCCESS:
      return {
        ...state,
        fields: {
          loaded: true,
          list: Object.values(action.payload.data),
        },
      };

    // Carregamento das configuraçoes dos campos
    case types.LOAD_CONFIGS_LOADING:
      return {
        ...state,
        configs: {
          isLoading: true,
        },
      };

    case types.LOAD_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: {
          loaded: true,
          ...action.payload.data,
        },
      };

    // Criando novo prospect (usuário)
    case types.PROSPECT_INSERT_LOADING:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          new: {
            isSaving: true,
          },
        },
      };

    case types.PROSPECT_INSERT_SUCCESS:
      return {
        ...state,
        prospects: {
          new: {
            isSaving: false,
            created: true,
            id: action && action.payload && action.payload.data,
          },
        },
      };

    case types.PROSPECT_INSERT_ERROR:
      return {};

    // Criando nova negociação
    case types.NEGOTIATION_CREATE_LOADING: {
      return {
        ...state,
        negotiations: {
          ...state.negotiations,
          new: {
            isSaving: true,
          },
        },
      };
    }

    case types.NEGOTIATION_CREATE_SUCCESS: {
      return {
        ...state,
        negotiations: {
          ...state.negotiations,
          new: {
            created: true,
          },
        },
      };
    }

    case types.NEGOTIATION_CREATE_ERROR: {
      const error =
        action.payload && action.payload.error && action.payload.error.data;

      return {
        ...state,
        negotiations: {
          ...state.negotiations,
          new: {
            errors: error && Object.values(error),
          },
        },
      };
    }

    default:
      return state;
  }
}
