import React from "react";
import Section from "../panels/Section";
import { formatCurrency } from "../../lib/utils";

const PanelOmniChannel = ({ summary }) => {
  let label = null;
  if (summary.ValorVendedorOmniGerenteSupervisor) {
    label = formatCurrency(summary.ValorVendedorOmniGerenteSupervisor);
  } else if (summary.ValorVendedorOmni) {
    label = formatCurrency(summary.ValorVendedorOmni);
  }

  return label && (
    <Section title="Vendas OmniChannel" label={label} collapsable={false} />
  );
};

export default PanelOmniChannel;
