import React, { Component } from "react";
import style from "./FormLayout.module.scss";

class FormLayout extends Component {
  renderField = (field, index) => (
    <div key={`field_${index}`} className={style.row}>
      {field}
    </div>
  );

  renderFooter = button => (
    <div key={button.props.id || "submit"} className={style.buttonContainer}>
      {button}
    </div>
  );

  onSubmit = e => {
    e.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <form className={style.form} onSubmit={this.onSubmit.bind(this)}>
        {this.props.fields.map(this.renderField)}
        <footer className={style.footer}>
          {this.props.buttons.map(this.renderFooter)}
        </footer>
      </form>
    );
  }
}

export default FormLayout;
