export default {
  PING_VERIFICATION: 'ACCESS/VERIFY_IP',
  PING_VERIFICATION_LOADING: 'ACCESS/VERIFY_IP/LOADING',
  PING_VERIFICATION_SUCCESS: 'ACCESS/VERIFY_IP/UPDATE',
  PING_VERIFICATION_ERROR: 'ACCESS/VERIFY_IP/ERROR',

  GRANT_ACCESS: 'ACCESS/GRANT',
  GRANT_ACCESS_LOADING: 'ACCESS/GRANT/LOADING',
  GRANT_ACCESS_SUCCESS: 'ACCESS/GRANT/SUCCESS',
  GRANT_ACCESS_ERROR: 'ACCESS/GRANT/ERROR'
};
