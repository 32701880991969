const STORAGE_NAME = 'SP_STORE_V2';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_NAME);
    if (serializedState === null) {
      return undefined;
    }

    const parsedState = JSON.parse(serializedState);
    return {
      session: parsedState.session || {},
      settings: parsedState.settings || {}
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    if (state.session) {
      const objToSave = {
        session: {
          user: state.session.user,
          viewAs: state.session.viewAs
        },
        settings: state.settings
      };
      const serializedState = JSON.stringify(objToSave);
      localStorage.setItem(STORAGE_NAME, serializedState);
    } else {
      localStorage.setItem(STORAGE_NAME, '');
    }
  } catch (err) {}
};
