import React from "react";
import style from "./IconBadge.module.scss";
import PropTypes from "prop-types";
import Icon from "../general/Icon";

const IconBadge = props => {
  const color = props.color || "#fff770";
  const size = props.size || "40px";
  const icon = props.icon || "calendar";

  return (
    <div className={style.icon}>
      <Icon name={icon} size={size} color={color} />
    </div>
  );
};

IconBadge.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string
};

export default IconBadge;
