import React from "react";
import style from "./PageTitle.module.scss";

const PageTitle = props => {
  return (
    <div className={style.header}>
      <div className={style.container}>
        <div className={style.wrap}>
          <h1>
            <span>{props.children}</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
