import React, { Component } from 'react';
import { withI18n, Trans } from 'react-i18next';

class I18n extends Component {
  render() {
    const key = this.props.i18nKey ? this.props.i18nKey : this.props.children;
    const params = this.props.params || {};

    if (this.props.i18nKey) {
      return (
        <Trans i18nKey={this.props.i18nKey} values={params} count={this.props.count}>
          {this.props.children}
        </Trans>
      );
    }

    return <>{this.props.t(key, params)}</>;
  }
}

export default withI18n()(I18n);