import React from "react";
import SelectBox from "../forms/SelectBox";
import TextInput from "../forms/TextInput";
import style from "./FormProspectField.module.scss";

const FormProspectField = ({ field, onChange }) => {
  const SELECTBOX_ID = 1;
  const TEXTFIELD_ID = 2;

  const renderSelectBoxField = () => {
    const options = field.Valores.map((option) => ({
      value: option.cd_campo_valor,
      name: option.tx_valor,
    }));

    return (
      <SelectBox
        id={`field-${field.cd_campo}`}
        name={field.cd_campo}
        placeholder={field.tx_label_campo}
        onChange={onChange}
        items={options}
      />
    );
  };

  const renderTextField = () => {
    return (
      <TextInput
        id={`field-${field.cd_campo}`}
        name={field.cd_campo}
        placeholder={field.tx_label_campo}
        onChange={onChange}
      />
    );
  };

  const renderAdditionalField = () => {
    return (
      <div className={style.additionalField}>
        <TextInput
          name={`additional-${field.cd_campo}`}
          placeholder={field.tx_label_campo_outros}
          onChange={onChange}
        />
      </div>
    );
  };

  return (
    <div className={style.row}>
      <label className={style.label} for={`field-${field.cd_campo}`}>
        {field.tx_label_campo}
      </label>

      <div className={style.fieldsContainer}>
        {field.TipoCampo.cd_tipo_campo === SELECTBOX_ID &&
          renderSelectBoxField()}
        {field.TipoCampo.cd_tipo_campo === TEXTFIELD_ID && renderTextField()}
        {field.tx_label_campo_outros && renderAdditionalField()}
      </div>
    </div>
  );
};

export default FormProspectField;
