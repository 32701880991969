import React from "react";
import PropTypes from "prop-types";
import style from "./WarnMe.module.scss";
import WarnMeListItem from "./WarnMeListItem";

const WarnMe = ({ purchase }) => {
  const items = purchase;

  return (

    <div className={style.purchase}>

      <ul className={style.itemList}>
        <WarnMeListItem item={items} />
      </ul>
    </div>
  );
};

WarnMe.propTypes = {
  purchase: PropTypes.object
};

export default WarnMe;
