import types from './types';

export default function(state = { loading: false }, action) {
  const idCustomer = action.key;

  switch (action.type) {
    case types.CONTACT_LOADING:
      return {
        [idCustomer]: { status: action.status }
      };

    case types.CONTACT_SAVED:
      return {
        [idCustomer]: { status: 'saved' }
      };

    case types.CONTACT_ERROR:
      return {
        [idCustomer]: {
          status: action.status,
          ...action.payload
        }
      };

    default:
      return state;
  }
}
