import types from "./types";

export const insertProspect = (customer) => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      url: `/prospeccao/cliente/cadastrar`,
      action: types.PROSPECT_INSERT,
      method: "POST",
      params: { ...customer },
    },
  });
};

export const startNewProspect = () => (dispatch) => {
  dispatch({
    type: types.PROSPECT_RESET_INSERT,
  });
};

export const loadFields = () => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      action: types.LOAD_FIELDS,
      url: "/prospeccao/campos",
    },
  });
};

export const loadConfigs = () => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      action: types.LOAD_CONFIGS,
      url: "/prospeccao/configuracao",
    },
  });
};

export const createNegotiation = (
  prospectId,
  vendedorId,
  negotiation,
  newProspect = true
) => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      action: types.NEGOTIATION_CREATE,
      url: "/prospeccao/negociacao/cadastrar",
      method: "POST",
      params: {
        cd_cliente_prospect: newProspect ? prospectId : null,
        cd_cliente_cadastrado: !newProspect ? prospectId : null,
        cod_vendedor: vendedorId,
        Negocicacao: [...negotiation], // 👀 👀 👀
      },
    },
  });
};
