import React from "react";
import InfoListItem from "../lists/InfoListItem";
import Accordion from "../panels/Accordion";
import moment from "moment";
import { formatCurrency } from "../../lib/utils";

const PanelPurchaseProfile = ({ customer }) => {
  return (
    <div>
      <Accordion title="Informações de Compras">
        <InfoListItem title="Data de Cadastro">
          {moment(customer.Resumo.Dt_Cliente_Dt_Cadastro).format("DD/MM/YYYY")}
        </InfoListItem>

        <InfoListItem title="Data da última visita">
          {moment(customer.Resumo.Dt_Cliente_Ultima_Compra).format(
            "DD/MM/YYYY"
          )}
        </InfoListItem>

        <InfoListItem title="Loja da última compra">
          {customer.Resumo.Tx_cliente_loja_ult_compra}
        </InfoListItem>

        <InfoListItem title="Valor da última compra">
          {formatCurrency(customer.Resumo.No_Cliente_Ultima_Compra_Valor)}
        </InfoListItem>

        <InfoListItem title="Valor acumulado">
          {formatCurrency(customer.Resumo.No_Valor_Acumulado)}
        </InfoListItem>

        <InfoListItem title="Valor em 12 meses">
          {formatCurrency(customer.Resumo.No_Valor_Ultimos_12)}
        </InfoListItem>

        <InfoListItem title="Valor em 13 - 24 meses">
          {formatCurrency(customer.Resumo.No_Valor_Ultimos_13_24)}
        </InfoListItem>

        <InfoListItem title="Qtd. de peças em 12 meses">
          {customer.Resumo.No_Pecas_Ulimos_12}
        </InfoListItem>

        <InfoListItem title="Frequência em 12 meses">
          {customer.Resumo.No_Freq_Ultimos_12}
        </InfoListItem>

        <InfoListItem title="Frequência em 13-24 meses">
          {customer.Resumo.No_Freq_Ultimos_13_24}
        </InfoListItem>

        <InfoListItem title="Trocas em 12 meses">
          {customer.Resumo.No_Cliente_Dev_Ultimos_12}
        </InfoListItem>
      </Accordion>
    </div>
  );
};

export default PanelPurchaseProfile;
