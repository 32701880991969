import {getTenant} from "./utils";

const baseURL = process.env.REACT_APP_API_URL;
const axios = require("axios");

const request = (url, data, method, settings) => {
  const tenant = getTenant(settings);
  return axios({
    url: baseURL + url,
    method,
    data,
    headers: {
      Authorization: "Basic SP1915CBD-EC15-44DA-A572-079BC9857355",
      "Content-Type": "application/json",
      "x-tenant": tenant
    }
  });
};

export const postRequest = (endpoint, params = {}, settings = {}) => {
  return request(endpoint, params, "post", settings = {});
};

export const getRequest = async (endpoint, params = {}, settings) => {
  return request(endpoint, params, "get", settings = {});
};

export const patchRequest = async (endpoint, params) => {
  return request(endpoint, params, "patch");
};

export const deleteRequest = async (endpoint, params) => {
  return request(endpoint, params, "delete");
};

export const putRequest = async (endpoint, params) => {
  return request(endpoint, params, "put");
};

export const getDataAndDispatch = (url, dispatch, id, types) => {
  dispatch({ type: types[0], id });
  getRequest(url)
    .then(res => {
      dispatch({ type: types[1], id, payload: res.data });
    })
    .catch(err => {
      dispatch({ type: types[2], id, error: err.response });
    });
};
