import React from "react";
import style from "./login.module.scss";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../store/ducks/session";
import { addSetting } from "../store/ducks/settings";
import { handleInputChange } from "../lib/utils";
import { isAuthenticated } from "../lib/auth";
import logo from "../static/assets/sp-logo-big.png";
import PageCentered from "../views/layouts/PageCentered";
import FormLayout from "../views/forms/FormLayout";
import TextInput from "../views/forms/TextInput";
import ActionButton from "../views/general/ActionButton";
import LoadingPanel from "../views/general/LoadingPanel";
import ErrorPanel from "../views/warnings/ErrorPanel";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        Login: "",
        Senha: "",
        Tenant: "",
      },
    };
    this.buttons = [<ActionButton onClick={() => {}}>Entrar</ActionButton>];
  }

  onLogin() {
    if (this.state.user.Tenant) {
      this.props.addSetting("tenant", this.state.user.Tenant);
    }

    this.props.onLogin(this.state.user);
  }

  render() {
    if (isAuthenticated(this.props.user)) {
      if (this.props.user.Perfil === 2) {
        return <Redirect to="/selecionar-vendedor" />;
      }

      if (this.props.user.Perfil === 3) {
        return <Redirect to="/selecionar-loja" />;
      }

      return <Redirect to="/dashboard" />;
    }

    const fields = [];

    if (
      !process.env.REACT_APP_FIXED_TENANT ||
      process.env.REACT_APP_FIXED_TENANT === "0" ||
      process.env.REACT_APP_FIXED_TENANT === "false"
    ) {
      fields.push(
        <TextInput
          id="tenant"
          name="Tenant"
          icon="store"
          value={this.state.user.Tenant}
          onChange={handleInputChange.bind(this, "user")}
          placeholder="Conta"
        />
      );
    }

    fields.push(
      <TextInput
        id="login"
        name="Login"
        icon="user"
        value={this.state.user.Login}
        onChange={handleInputChange.bind(this, "user")}
        placeholder={"loginOrCode"}
      />
    );

    fields.push(
      <TextInput
        id="senha"
        name="Senha"
        type="password"
        icon="lock"
        value={this.state.user.Senha}
        onChange={handleInputChange.bind(this, "user")}
        placeholder={"password"}
      />
    );

    return (
      <PageCentered>
        <div className={style.pageContainer}>
          <i className={style.logo}>
            <img alt="" src={logo} />
          </i>
          <ErrorPanel errorMessage={this.props.error} />
          <FormLayout
            onSubmit={this.onLogin.bind(this)}
            fields={fields}
            buttons={this.buttons}
          />
          <LoadingPanel isLoading={this.props.loading} />
        </div>
      </PageCentered>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  error: session.error,
  loading: session.status === "loading",
  response: session.response,
  user: session.user,
});

const mapDispatchToProps = {
  onLogin: login,
  addSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
