/* eslint-disable no-useless-escape */
import types from "./types";

export const loadCustomer = (customerId) => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      url: `/clientes/${customerId}/resumo`,
      action: types.LOAD_CUSTOMER,
      key: customerId,
    },
  });
};

export const searchCustomer = (cpfOrPhone) => (dispatch) => {
  const userId = cpfOrPhone.replace(/[\-\.]/g, "");
  dispatch({
    type: "API",
    meta: {
      url: `/clientes/${userId}`,
      action: types.LOAD_CUSTOMER,
      key: userId,
    },
  });
};

export const loadMotivosInsucesso = () => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      url: `/clientes/motivoinsucesso`,
      action: types.LOAD_MOTIVOS_INSUCESSO,
    },
  });
};

export const loadVigencia = (customerId) => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      url: `/clientes/${customerId}/vigencia`,
      action: types.LOAD_VIGENCIA,
      key: customerId,
    },
  });
};

export const sendSMS = (customerId) => (dispatch) => {
  dispatch({
    type: "API",
    meta: {
      url: `/clientes/${customerId}/sms`,
      action: types.SEND_SMS,
      key: customerId,
      method: "POST",
    },
  });
};

export const resetCustomerData = (customerId) => (dispatch) => {
  dispatch({ type: types.RESET, key: customerId });
};

export const saveComment = (customerId, comment) => (dispatch, getStore) => {
  const store = getStore();
  const idVendedor = store.session.viewAs || store.session.user.VendedorId;
  if (!idVendedor) {
    throw new Error("Usuário não autenticado na aplicação.");
  }

  dispatch({
    type: "API",
    meta: {
      url: `/clientes/observacao`,
      method: "POST",
      action: types.SAVE_COMMENT,
      key: customerId,
      additionalPayloadData: { comment },
      params: {
        IdCliente: customerId,
        IdVendedor: idVendedor,
        TextoObservacao: comment,
      },
    },
  });
};
