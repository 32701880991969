import React from "react";
import PropTypes from "prop-types";
import style from "./InfoListItem.module.scss";

const InfoListItem = props => {
  return (
    <div className={style.itemContainer}>
      <div className={style.title}>{props.title}</div>
      <div className={style.content}>{props.children || `—`}</div>
    </div>
  );
};

InfoListItem.propTypes = {
  title: PropTypes.string
};

export default InfoListItem;
