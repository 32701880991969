import React from "react";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../store/ducks/session";
import { connect } from "react-redux";

const logout = props => {
  if (props.session && props.session.user) {
    props.logoutUser();
    return null;
  }

  return <Redirect to="/" />;
};

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = { logoutUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(logout);
