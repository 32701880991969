import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AuthManager from "../views/enhancers/AuthManager";
import FormProspect from "../views/prospects/FormProspect";
import Loading from "../views/general/Loading";
import LoadingPanel from "../views/general/LoadingPanel";
import PageInternal from "../views/layouts/PageInternal";
import Section from "../views/panels/Section";
import { loadCustomer } from "../store/ducks/customers";
import {
  insertProspect,
  startNewProspect,
  loadFields,
  createNegotiation,
} from "../store/ducks/prospects";

import style from "./prospectInsert.module.scss";

const ProspectUpdate = ({
  customers,
  fields,
  session,
  negotiation,
  startNewProspect,
  loadFields,
  insertProspect,
  createNegotiation,
  loadCustomer,
  ...props
}) => {
  const [errors] = useState({});
  const clientId =
    props.match && props.match.params && props.match.params.clienteId;

  const customer = customers && customers[clientId];
  const isLoadingCustomer =
    !customer || customer.status !== "loaded" || !customer.data.Resumo;

  // Evento executado na inicialização do componente:
  useEffect(() => {
    loadCustomer(clientId);
    if (fields && !fields.loaded) {
      loadFields();
    }
  }, []);

  useEffect(() => {
    if (negotiation.created) {
      props.history.push("/dashboard");
    }
  }, [negotiation]);

  const onSubmit = (values) => {
    createNegotiation(
      customer.data.Resumo.Cd_Cliente,
      session.user.VendedorId,
      values.negotiation,
      false
    );
  };

  const renderErrorMessage = () => {
    return (
      <React.Fragment>
        {Object.values(errors).map((error) => (
          <div className={style.error}>{error}</div>
        ))}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <FormProspect
          customer={customer && customer.data}
          fields={fields.list}
          onSubmit={onSubmit}
        />
        {Object.values(errors).length > 0 && (
          <div className={style.errors}>{renderErrorMessage()}</div>
        )}
      </React.Fragment>
    );
  };

  console.log(">>>>", isLoadingCustomer, customers);
  return (
    <AuthManager>
      <PageInternal>
        <div className={style.pageContainer}>
          {isLoadingCustomer && <Loading />}
          {!isLoadingCustomer && (
            <Section
              title="Nova Negociação"
              collapsable={false}
              content={renderContent()}
            />
          )}
        </div>
      </PageInternal>
      <LoadingPanel isLoading={negotiation.isSaving} />
    </AuthManager>
  );
};

const mapStateToProps = ({ customers, prospects, session }) => {
  return {
    customers,
    negotiation:
      prospects.negotiations && prospects.negotiations.new
        ? prospects.negotiations.new
        : {},
    fields: prospects.fields,
    session,
  };
};

const mapDispatchToProps = {
  insertProspect,
  startNewProspect,
  createNegotiation,
  loadFields,
  loadCustomer,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProspectUpdate)
);
