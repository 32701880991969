import React from "react";
import Accordion from "../panels/Accordion";
import WarnMe from "../lists/WarnMe";

const PanelWarnMe = ({ customer }) => {
  if (!customer.AviseMeProdutos) return null;

  return (
    <div>
      <Accordion title="Produtos do Avise-me">
        {customer.AviseMeProdutos.map(el => (
          <WarnMe purchase={el} />
        ))}
      </Accordion>
    </div>
  );
};

export default PanelWarnMe;
