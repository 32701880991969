const PanelDesiredProducts = ({ customer }) => {
    const getLastPurchase = customer =>
        customer.HistoricoCompras &&
        customer.HistoricoCompras.length > 0 &&
        customer.HistoricoCompras[0];

    const lastPurchase = getLastPurchase(customer);
    if (!lastPurchase) return null;

    return null;

    /*
    return (
        <div>
            <Accordion title="Produtos Desejados">
                <Purchase purchase={lastPurchase} />
            </Accordion>
        </div>
    );
    */
};

export default PanelDesiredProducts;
