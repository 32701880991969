import React from 'react';
import Section from '../panels/Section';
import Table from '../lists/Table';
import { formatCurrency, formatPercentage } from '../../lib/utils';

const PanelMetaRealizadoMes = ({ summary, isSupervisor }) => {
  const header = ['Metas', 'Valor', 'Alcançado (%)'];
  let metaKey, superMetaKey, salesKey;

  if (isSupervisor) {
    metaKey = 'TotalMetaGerenteSupervisor';
    superMetaKey = 'TotalSuperMetaGerenteSupervisor';
    salesKey = 'TotalVendasGrupo';
  } else {
    metaKey = 'ValorMetaVendedor';
    superMetaKey = 'ValorSuperMetaVendedor';
    salesKey = 'ValorVendedor';
  }

  const sales = summary[salesKey];
  const rows = [];

  if (summary[metaKey]) {
    const goal = Math.max(0, summary[metaKey]);
    rows.push(['Meta', formatCurrency(goal), formatPercentage(sales / goal)]);
  }

  if (summary[superMetaKey]) {
    const superGoal = Math.max(1, summary[superMetaKey]);
    rows.push([
      'Super Meta',
      formatCurrency(superGoal),
      formatPercentage(sales / superGoal)
    ]);
  }

  if (rows.length === 0) {
    return null;
  }

  const table = <Table rows={rows} header={header} />;
  return (
    <Section
      label={formatCurrency(sales)}
      title="Meta X Realizado / Mês"
      content={table}
      collapsable={true}
    />
  );
};

export default PanelMetaRealizadoMes;
