export default {
  USER_LOGIN: 'SESSION/LOGIN',
  USER_LOGIN_LOADING: 'SESSION/LOGIN/LOADING',
  USER_LOGIN_SUCCESSFUL: 'SESSION/LOGIN/SUCCESS',
  USER_LOGIN_ERROR: 'SESSION/LOGIN/ERROR',
  USER_LOGOUT: 'SESSION/LOGOUT',

  SET_CURRENT_VIEW: 'SESSION/USER/UPDATE_CURRENT_VIEW',

  USER_UPDATE: 'SESSION/USER_UPDATE',
  USER_UPDATE_LOADING: 'SESSION/USER_UPDATE/LOADING',
  USER_UPDATE_SUCCESS: 'SESSION/USER_UPDATE/SUCCESS',
  USER_UPDATE_ERROR: 'SESSION/USER_UPDATE/ERROR'
};
