import React from "react";
import PropTypes from "prop-types";
import style from "./ErrorPanel.module.scss";
import I18n from "../enhancers/I18n";
import WarningBadge from "../badges/WarningBadge";

const ErrorPanel = props => {
  return props.errorMessage ? (
    <div className={style.errorLogin}>
      <div className={style.container}>
        <div className={style.grid}>
          <WarningBadge />
          <span className={style.label}>
            <I18n>{props.errorMessage}</I18n>
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

ErrorPanel.propTypes = {
  errorMessage: PropTypes.string
};

export default ErrorPanel;
