import React from 'react';
import style from './CustomerHeaderName.module.scss';

const CustomerHeaderName = props => {
  return (
    <div className={style.container}>
      <div className={style.name}>{props.name}</div>
    </div>
  );
};

export default CustomerHeaderName;
