export default {
  LOAD_RESUMO: 'STORES/RESUMO',
  LOADING_RESUMO: 'STORES/RESUMO/LOADING',
  UPDATE_RESUMO: 'STORES/RESUMO/UPDATE',
  ERROR_RESUMO: 'STORES/RESUMO/ERROR',

  LOAD_SCHEDULE: 'STORES/SCHEDULE',
  LOADING_SCHEDULE: 'STORES/SCHEDULE/LOADING',
  UPDATE_SCHEDULE: 'STORES/SCHEDULE/UPDATE',
  ERROR_SCHEDULE: 'STORES/SCHEDULE/ERROR',

  LOAD_OVERVIEW: 'STORES/OVERVIEW',
  LOADING_OVERVIEW: 'STORES/OVERVIEW/LOADING',
  UPDATE_OVERVIEW: 'STORES/OVERVIEW/UPDATE',
  ERROR_OVERVIEW: 'STORES/OVERVIEW/ERROR',

  LOAD_LIST: 'STORES/LIST',
  LOADING_LIST: 'STORES/LIST/LOADING',
  UPDATE_LIST: 'STORES/LIST/UPDATE',
  ERROR_LIST: 'STORES/LIST/ERROR'
};
