import React from "react";
import PropTypes from "prop-types";
import Collapsible from "react-collapsible";
import style from "./Accordion.module.scss";
import Icon from "../general/Icon";
import classnames from "classnames";

const Accordion = props => {
  const iconClosed = (
    <div className={classnames(style.icon, style.iconMinus)}>
      <Icon name="minusCircle" color="#ffffff" />
    </div>
  );

  const iconOpen = (
    <div className={classnames(style.icon, style.iconPlus)}>
      <Icon name="plusCircle" color="#ffffff" />
    </div>
  );

  const title = (
    <div className={style.header}>
      {props.title}
      {iconClosed}
      {iconOpen}
    </div>
  );

  return (
    <div className={style.accordion}>
      <Collapsible trigger={title}>{props.children}</Collapsible>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string
};

export default Accordion;
