import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./Purchase.module.scss";
import InfoListItem from "./InfoListItem";
import PurchasedListItem from "./PurchasedListItem";
import { formatCurrency } from "../../lib/utils";

const Purchase = ({ purchase }) => {
  const firstItem = purchase.Items[0];
  const items = purchase.Items;

  return (
    <div className={style.purchase}>
      <header className={style.header}>
        {moment(firstItem.dt_venda).format("DD/MM/YYYY")}
      </header>

      <div className={style.infoContainer}>
        <InfoListItem title={firstItem.tx_loja}>
          {firstItem.cd_loja}
        </InfoListItem>

        {firstItem.VENDA_LOJA_ORIG && <InfoListItem title="Filial Compra">
           {firstItem.VENDA_LOJA_ORIG}
        </InfoListItem>}

        <InfoListItem title={firstItem.tx_vendor}>
          {firstItem.cd_vendor}
        </InfoListItem>

        <InfoListItem
          title={`Valor informado: ${firstItem.no_qtd_peca} peça(s)`}
        >
          {formatCurrency(firstItem.no_valor_pago)}
        </InfoListItem>

        <InfoListItem title="Operação de Venda">
          {firstItem.tx_tipo_venda}
        </InfoListItem>
      </div>

      <ul className={style.itemList}>
        {items.map(item => (
          <PurchasedListItem item={item} />
        ))}
      </ul>
    </div>
  );
};

Purchase.propTypes = {
  purchase: PropTypes.object
};

export default Purchase;
