import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AuthManager from "../views/enhancers/AuthManager";
import PageInternal from "../views/layouts/PageInternal";
import FormProspect from "../views/prospects/FormProspect";
import Section from "../views/panels/Section";
import LoadingPanel from "../views/general/LoadingPanel";
import Loading from "../views/general/Loading";
import {
  insertProspect,
  startNewProspect,
  loadFields,
  loadConfigs,
  createNegotiation,
} from "../store/ducks/prospects";
import { validarCpf, validateEmail } from "../lib/utils";
import style from "./prospectInsert.module.scss";

const ProspectInsert = ({
  prospect,
  fields,
  session,
  configs,
  negotiation,
  startNewProspect,
  loadFields,
  loadConfigs,
  insertProspect,
  createNegotiation,
  ...props
}) => {
  const [errors, setErrors] = useState({});
  const [parsedNegotiation, setNegotiation] = useState({});
  const isLoadingFields = fields && fields.isLoading;
  const isLoadingConfigs = configs && configs.isLoading;
  const isLoading = isLoadingFields || isLoadingConfigs;

  // Evento executado na inicialização do componente:
  useEffect(() => {
    setNegotiation({});
    startNewProspect();
    if (fields && !fields.loaded) {
      loadFields();
    }

    if (configs && !configs.loaded) {
      loadConfigs();
    }
  }, []);

  // Quando prospect muda / é criado, roda o seguinte código:
  useEffect(() => {
    if (prospect.created && prospect.id) {
      createNegotiation(
        prospect.id,
        session.user.VendedorId,
        parsedNegotiation
      );
    }
  }, [prospect]);

  useEffect(() => {
    if (negotiation.created) {
      props.history.push("/dashboard");
    }
  }, [negotiation]);

  const validateValues = (values) => {
    const localErrors = {};

    if (!configs || !configs.loaded) {
      return false;
    }

    if (
      configs.fl_nome_obrigatorio &&
      (!values.name || values.name.length < 2)
    ) {
      localErrors.name = "Nome inválido";
    }

    if (
      configs.fl_telefone_obrigatorio &&
      (!values.phone || values.phone.length < 8)
    ) {
      localErrors.phone = "Telefone inválido";
    }

    if (
      (configs.fl_email_obrigatorio || values.email) &&
      !validateEmail(values.email)
    ) {
      localErrors.email = "E-mail inválido";
    }

    if ((configs.fl_cpf_obrigatorio || values.cpf) && !validarCpf(values.cpf)) {
      localErrors.cpf = "E-mail inválido";
    }

    setErrors({ ...localErrors });
    return Object.keys(localErrors).length === 0;
  };

  const onSubmit = (values) => {
    if (validateValues(values)) {
      setNegotiation(values.negotiation);
      insertProspect({
        tx_nome: values.name,
        tx_telefone: values.phone,
        tx_email: values.email,
        tx_cpf: values.cpf,
      });
    }
  };

  const renderErrorMessage = () => {
    return (
      <React.Fragment>
        {Object.values(errors).map((error) => (
          <div className={style.error}>{error}</div>
        ))}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <FormProspect create={true} fields={fields.list} onSubmit={onSubmit} />
        {Object.values(errors).length > 0 && (
          <div className={style.errors}>{renderErrorMessage()}</div>
        )}
      </React.Fragment>
    );
  };

  return (
    <AuthManager>
      <PageInternal>
        <div className={style.pageContainer}>
          {isLoading && <Loading />}
          {!isLoading && (
            <Section
              title="Novo Prospect"
              collapsable={false}
              content={renderContent()}
            />
          )}
        </div>
      </PageInternal>
      <LoadingPanel isLoading={prospect.isSaving} />
    </AuthManager>
  );
};

const mapStateToProps = ({ prospects, session }) => {
  if (
    prospects.prospects &&
    prospects.prospects.new &&
    prospects.negotiations &&
    prospects.negotiations.new
  ) {
    return {
      prospect: prospects.prospects.new,
      negotiation: prospects.negotiations.new,
      fields: prospects.fields,
      configs: prospects.configs,
      session,
    };
  }

  return {
    prospect: {},
    negotiation: {},
    fields: prospects.fields,
    configs: prospects.configs,
    session,
  };
};

const mapDispatchToProps = {
  insertProspect,
  startNewProspect,
  createNegotiation,
  loadFields,
  loadConfigs,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProspectInsert)
);
