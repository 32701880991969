import React from "react";
import style from "./ChartBarContainer.module.scss";
import ChartBar from "./ChartBar";
import PropTypes from "prop-types";

const ChartBarContainer = props => {
  if (!props.data || !props.data.length) {
    return null;
  }

  const unsafeValue = Math.max.apply(null, props.data.map(el => el.value));
  const maxValue = Math.max(0.01, unsafeValue);

  const dataArray = props.data
    .filter(el => el.value !== null)
    .map(el => ({ ...el, percentage: el.value / maxValue }));

  const chartBars = dataArray.map(el => (
    <div className={style.barContainer}>
      <ChartBar
        data={el}
        formatMoney={props.formatMoney}
        percentage={props.percentage}
        startAnimation={props.visible}
      />
    </div>
  ));

  return <div className={style.chartPanel}>{chartBars}</div>;
};

ChartBarContainer.propTypes = {
  data: PropTypes.array,
  formatMoney: PropTypes.bool
};

export default ChartBarContainer;
