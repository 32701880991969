import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AuthManager from '../views/enhancers/AuthManager';
import PageInternal from '../views/layouts/PageInternal';
import Section from '../views/panels/Section';
import SelectBox from '../views/forms/SelectBox';
import FormLayout from '../views/forms/FormLayout';
import Button from '../views/forms/Button';
import { loadSellersByManager } from '../store/ducks/sellers';
import { setSelectedUser } from '../store/ducks/session';
import style from './selectSeller.module.scss';

class SelectSeller extends Component {
  state = {
    seller: ''
  };

  componentWillMount() {
    this.props.loadSellersByManager(this.props.session.user.VendedorId);
  }

  getSellersList() {
    if (this.props.sellers.list.status === 'loaded') {
      return this.props.sellers.list.data
        .map(s => ({
          value: s.Id,
          name: s.Nome
        }))
        .sort((v1, v2) => (v1.name > v2.name ? 1 : -1));
    }
    return [];
  }

  setSelectedUser() {
    const currentUser = this.state.seller || '';
    this.props.setSelectedUser(currentUser);

    if (currentUser) {
      this.props.history.push('/dashboard');
    } else {
      const storeId = this.props.session.user.Loja;
      this.props.history.push('/dashboard?store=' + storeId);
    }
  }

  renderSectionContent() {
    const select = (
      <SelectBox
        placeholder="(Todos os Vendedores)"
        loading={this.props.sellers.list.status === 'loading'}
        items={this.getSellersList()}
        onChange={e => this.setState({ seller: e.target.value })}
      />
    );

    const submitButton = (
      <Button onClick={this.setSelectedUser.bind(this)}>Acessar</Button>
    );

    return <FormLayout fields={[select]} buttons={[submitButton]} />;
  }

  render() {
    return (
      <AuthManager accessLevel="gerente">
        <PageInternal>
          <div className={style.container}>
            <Section
              content={this.renderSectionContent()}
              title="Seleção de Vendedor"
              collapsable={false}
            />
          </div>
        </PageInternal>
      </AuthManager>
    );
  }
}

const mapDispatchToProps = { loadSellersByManager, setSelectedUser };
const mapStateToProps = ({ session, sellers }) => ({ session, sellers });

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectSeller)
);
