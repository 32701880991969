import languages from "../../../languages";
import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import types from './types';

i18n.use(reactI18nextModule).init({
  resources: { ...languages },
  lng: "pt",
  fallbackLng: "pt"
});

export default function(state = {}, action) {
  switch(action.type) {
    case types.ADD_SETTING:
      const {key, value} = action;
      return {
        ...state,
        [key]: value
      };
    
    default:
      return state;
  }
}
