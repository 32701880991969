import types from './types';

export const scheduleContact = (idCustomer, contact) => dispatch => {
  const contactData = {
    CD_REGRA: contact.regra,
    CD_TIPO_RESULTADO: contact.contatoRealizado ? 1 : 2,
    CD_TP_CONTATO: contact.idTipoContato,
    CD_TERMOMETRO: contact.thermometer,
    TX_DESCRICAO: contact.notes,
    CD_VENDEDOR: contact.vendedor,
    DT_PROX_CONTATO: contact.nextDate,
    FL_NAO_REAGENDAR: !contact.reagendar,
    CD_MOTIVO_INSUCESSO: contact.motivoInsucesso
    };

  dispatch({
    type: 'API',
    meta: {
      url: `/clientes/${idCustomer}/contatos`,
      action: types.CONTACT_SAVE,
      method: 'POST',
      params: contactData,
      key: idCustomer
    }
  });
};