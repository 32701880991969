import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadResumo, loadSchedule } from '../store/ducks/sellers/thunks';
import {
  loadStoreResumo,
  loadStoreSchedule,
  loadStoreGroupOverview
} from '../store/ducks/stores/thunks';
import AuthManager from '../views/enhancers/AuthManager';
import Dashboard from '../views/dashboard/Dashboard';
import PageInternal from '../views/layouts/PageInternal';
import { withRouter } from 'react-router-dom';
import { parseQuery } from '../lib/utils';
import StoreDashboard from '../views/dashboard/StoreDashboard';

export class DashboardContainer extends Component {
  getSelectedStore = () => {
    const parsedQuery = parseQuery(this.props.location.search);
    return parsedQuery['store'];
  };

  get currentSellerId() {
    const { session } = this.props;
    if (session.user.Perfil === 1) {
      return session.user.VendedorId;
    }

    if (session.user.Perfil >= 2) {
      return session.viewAs || session.user.VendedorId;
    }
    return undefined;
  }

  isViewingStoreData = () => !!this.getSelectedStore();

  componentWillMount() {
    const {
      session,
      loadResumo,
      loadSchedule,
      loadStoreGroupOverview,
      loadStoreResumo,
      loadStoreSchedule
    } = this.props;

    if (session && session.user) {
      // Vendedor
      if (session.user.Perfil === 1) {
        loadResumo(this.currentSellerId);
        loadSchedule(this.currentSellerId);
      }

      // Gerente ou Administrador visualizando os dados de um vendedor
      if (session.user.Perfil >= 2 && !this.isViewingStoreData()) {
        loadResumo(this.currentSellerId);
        loadSchedule(this.currentSellerId);
      }

      // Administrador visualizando os dados da loja
      if (session.user.Perfil >= 2 && this.isViewingStoreData()) {
        const storeId = this.getSelectedStore();
        loadStoreResumo(session.user.VendedorId, storeId);
        loadStoreGroupOverview(session.user.VendedorId, storeId);
        loadStoreSchedule(storeId);
      }
    }
  }

  getSeller() {
    if (this.currentSellerId) {
      return this.props.sellers[this.currentSellerId];
    }
    return null;
  }

  renderUserDashboard() {
    const seller = this.getSeller();
    return (
      <AuthManager accessLevel="vendedor">
        <PageInternal>
          <Dashboard
            isViewingStoreData={this.isViewingStoreData()}
            user={this.props.session.user}
            seller={seller}
          />
        </PageInternal>
      </AuthManager>
    );
  }

  renderStoreDashboard() {
    const storeId = this.getSelectedStore();
    return (
      <AuthManager accessLevel="gerente">
        <PageInternal>
          <StoreDashboard storeId={storeId} />
        </PageInternal>
      </AuthManager>
    );
  }

  render() {
    if (this.isViewingStoreData()) {
      return this.renderStoreDashboard();
    } else {
      return this.renderUserDashboard();
    }
  }
}

const mapStateToProps = ({ session, sellers, stores }) => ({
  session,
  sellers,
  stores
});

const mapDispatchToProps = {
  loadResumo,
  loadSchedule,
  loadStoreSchedule,
  loadStoreResumo,
  loadStoreGroupOverview
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardContainer)
);
