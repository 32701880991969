/* eslint-disable no-useless-escape */
import types from './types';

export const registerUser = clientData => dispatch => {
  const userId = clientData.cpf.replace(/[\-\.]/g, '');
  const client = {
    Id: userId,
    CPF: clientData.cpf,
    Email: clientData.email,
    Nome: clientData.nome,
    Telefone: clientData.telefone
  };

  dispatch({
    type: 'API',
    meta: {
      url: `/aviseme/clientes`,
      method: 'POST',
      params: client,
      additionalPayloadData: { client },
      action: types.SAVE,
      key: userId
    }
  });
};

export const registerItems = (clientData, products) => (dispatch, getState) => {
  const userId = clientData.cpf.replace(/[\-\.]/g, '');
  const currentState = getState();

  if (!currentState.session || !currentState.session.user) {
    return false;
  }

  const user = currentState.session.user;
  const data = {
    CodCliente: userId,
    CodVendedor: user.VendedorId,
    Produtos: products
  };

  dispatch({
    type: 'API',
    meta: {
      url: `/aviseme`,
      method: 'POST',
      action: types.SAVE_PRODUCTS,
      params: data,
      key: userId
    }
  });
};

export const resetWarnMeData = () => dispatch => {
  dispatch({ type: types.RESET });
};
