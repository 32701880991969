import React from "react";
import Loading from "../general/Loading";
import I18n from "../enhancers/I18n";
import WarningPanel from "../warnings/WarningPanel";
import PropTypes from "prop-types";

const ContactWarning = props => {
  const seller = props.seller;
  let text;

  if (!seller || !seller.schedule || seller.schedule.status === "loading") {
    text = <Loading />;
  } else {
    const scheduleLength = seller.schedule.data.length;
    if (!scheduleLength) {
      return null;
    }
    text = scheduleLength && (
      <I18n
        i18nKey="contactsToMake"
        count={scheduleLength}
        params={{ contacts: scheduleLength }}
      >
        Hoje você tem <strong>x contatos para fazer</strong>
      </I18n>
    );
  }

  return !!text && <WarningPanel icon="calendar" text={text} link="/agendas" />;
};

ContactWarning.propTypes = {
  seller: PropTypes.object.isRequired
};

export default ContactWarning;
