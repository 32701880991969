import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./NavigationMenu.module.scss";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
const cl = require("classnames");

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    };
  }

  get homeUrl() {
    if (!this.props.user) {
      return "/";
    }

    switch (this.props.user.Perfil) {
      case 1: 
        return '/dashboard';

      case 2:
        return '/selecionar-vendedor';

      case 3:
        return '/selecionar-loja';

      default: 
        return '/';
    }
  }

  toggleMenu = () =>
    this.setState(prevState =>
      this.setState({ isOpened: !prevState.isOpened })
    );

  getNavigationItems = () =>
    this.props.items.map(el => (
      <li key={el.link}>
        <Link onClick={() => this.setState({ isOpened: false })} to={el.link}>
          {el.title}
        </Link>
      </li>
    ));

  render() {
    return (
      <div>
        <header
          className={cl(style.menu, this.state.isOpened && style.menuOpened)}
        >
          <div className={style.container}>
            <div className={style.grid}>
              <h1>
                <Link to={this.homeUrl}>
                  <img src="/static/images/guru-vendas-top.png" alt="Sales Push" />
                </Link>
              </h1>
              <button
                className={style.buttonMenu}
                onClick={this.toggleMenu.bind(this)}
              >
                Menu
                <span />
                <span />
                <span />
                <span />
              </button>
              <nav className={style.navContainer}>
                <ul>{this.getNavigationItems()}</ul>
              </nav>
            </div>
          </div>
        </header>
        {this.state.isOpened && <div className={style.overlay} />}
      </div>
    );
  }
}

NavigationMenu.propTypes = {
  items: PropTypes.array
};

const mapStateToProps = ({session}) => ({user: session.user});

export default connect(mapStateToProps)(NavigationMenu);
