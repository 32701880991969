import React from "react";
import { Link } from "react-router-dom";
import style from "./WarningPanel.module.scss";
import WarningBadge from "../../views/badges/WarningBadge";
import PropTypes from "prop-types";
import IconBadge from "../badges/IconBadge";

const WarningPanel = props => {
  return (
    <section className={style.panel}>
      <div className={style.container}>
        <Link to={props.link} className={style.wrap}>
          <div className={style.wrapContent}>
            <WarningBadge color="#ff034c" />
            <span className={style.label}>{props.text}</span>
          </div>
          <IconBadge icon={props.icon} />
        </Link>
      </div>
    </section>
  );
};

WarningPanel.propTypes = {
  text: PropTypes.any,
  link: PropTypes.string,
  icon: PropTypes.string
};

export default WarningPanel;
