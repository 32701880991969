import types from './types';

export default (state = {}, action) => {
  const customer = (action.key && state[action.key]) || {};

  switch (action.type) {
    case types.CUSTOMER_LOADING:
    case types.CUSTOMER_ERROR:
      return {
        ...state,
        [action.key]: {
          ...customer,
          ...action.payload,
          status: action.status
        }
      };

    // Sistema carregou os dados do cliente com sucesso
    case types.CUSTOMER_UPDATE:
      return {
        ...state,
        lastUpdated: new Date(),
        [action.key]: {
          ...customer,
          status: action.status,
          data: action.payload.data || {}
        }
      };

    // Sistema carregando os dados do período de vigência
    case types.CUSTOMER_VIGENCIA_LOADING:
    case types.CUSTOMER_VIGENCIA_UPDATE:
    case types.CUSTOMER_VIGENCIA_ERROR:
      return {
        ...state,
        [action.key]: {
          ...customer,
          Vigencia: {
            status: action.status,
            ...action.payload
          }
        }
      };

    case types.LOAD_MOTIVOS_INSUCESSO_LOADING:
    case types.LOAD_MOTIVOS_INSUCESSO_UPDATE:
    case types.LOAD_MOTIVOS_INSUCESSO_ERROR:
      return {
        ...state,
        MotivosInsucesso: {
          status: action.status,
          ...action.payload
        }
      };

    case types.RESET:
      return {
        ...state,
        [action.key]: null
      };

    case types.SEND_SMS_LOADING:
    case types.SEND_SMS_ERROR:
    case types.SEND_SMS_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...customer,
          sms: {
            status: action.status
          }
        }
      };

    case types.SAVE_COMMENT_LOADING:
      return {
        ...state,
        [action.key]: {
          ...customer,
          savingComment: true
        }
      };

    case types.SAVE_COMMENT_SUCCESS:
      if (Array.isArray(customer.Observacoes)) {
        customer.Observacoes.push(action.payload.comment);
      } else {
        customer.Observacoes = [action.payload.comment];
      }

      return {
        ...state,
        lastCommentSaved: new Date(),
        [action.key]: {
          ...customer,
          savingComment: false
        }
      };

    case types.SAVE_COMMENT_ERROR:
      return {
        ...state,
        [action.key]: {
          ...customer,
          status: 'error',
          savingComment: false
        }
      };

    default:
      return state;
  }
};
