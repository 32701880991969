export default {
  PROSPECT_RESET_INSERT: "PROSPECT/INSERT/RESET",
  PROSPECT_INSERT: "PROSPECT/INSERT",
  PROSPECT_INSERT_LOADING: "PROSPECT/INSERT/LOADING",
  PROSPECT_INSERT_ERROR: "PROSPECT/INSERT/ERROR",
  PROSPECT_INSERT_SUCCESS: "PROSPECT/INSERT/SUCCESS",

  LOAD_FIELDS: "PROSPECT/FIELDS",
  LOAD_FIELDS_LOADING: "PROSPECT/FIELDS/LOADING",
  LOAD_FIELDS_ERROR: "PROSPECT/FIELDS/ERROR",
  LOAD_FIELDS_SUCCESS: "PROSPECT/FIELDS/UPDATE",

  LOAD_CONFIGS: "PROSPECT/CONFIGS",
  LOAD_CONFIGS_LOADING: "PROSPECT/CONFIGS/LOADING",
  LOAD_CONFIGS_ERROR: "PROSPECT/CONFIGS/ERROR",
  LOAD_CONFIGS_SUCCESS: "PROSPECT/CONFIGS/UPDATE",

  NEGOTIATION_CREATE: "PROSPECT/NEW_NEGOTIATION",
  NEGOTIATION_CREATE_LOADING: "PROSPECT/NEW_NEGOTIATION/LOADING",
  NEGOTIATION_CREATE_ERROR: "PROSPECT/NEW_NEGOTIATION/ERROR",
  NEGOTIATION_CREATE_SUCCESS: "PROSPECT/NEW_NEGOTIATION/SUCCESS",
};
