import React from "react";
import style from "./login.module.scss";
import { Link } from 'react-router-dom';
import logo from "../static/assets/sp-logo-big.png";
import PageCentered from "../views/layouts/PageCentered";
import ErrorPanel from "../views/warnings/ErrorPanel";
import ActionButton from "../views/general/ActionButton";

class AccessDenied extends React.Component {
  render() {
    const message = "Para liberar o aplicativo, solicite ao seu gerente que faça o login " +
      "no sistema conectado no wifi ou de um computador da loja.";

    return (
      <PageCentered>
        <i className={style.logo}>
          <img alt="" src={logo} />
        </i>
        <ErrorPanel errorMessage={message} />
        <Link to="/liberar-acesso">
          <ActionButton>Liberar Acesso</ActionButton>
        </Link>
      </PageCentered>
    );
  }
}

export default AccessDenied;
