import React from 'react';
import DatePicker from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import style from './DateInput.module.scss';
import PropTypes from 'prop-types';

const DateInput = props => {
  const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  const WEEKDAYS_LONG = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
  ];

  const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  let datePickerProps = {
    placeholder: '',
    formatDate: date => moment(date).format('DD/MM/YYYY'),
    localeUtils: MomentLocaleUtils,
    locale: 'pt-br',
    inputProps: { className: style.field },
    onDayChange: props.onChange,
    dayPickerProps: {
      months: MONTHS,
      weekdaysLong: WEEKDAYS_LONG,
      weekdaysShort: WEEKDAYS_SHORT,
      disabledDays: {
        before: props.startDate || null
      }
    }
  };

  if (props.endDate) {
    datePickerProps.dayPickerProps.disabledDays.after = props.endDate;
  }

  return <DatePicker {...datePickerProps} />;
};

DateInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default DateInput;
