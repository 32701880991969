import types from './types';

export const loadStoreList = userId => dispatch => {
  dispatch({
    type: 'API',
    meta: {
      url: `/vendedores/${userId}/lojas`,
      action: types.LOAD_LIST
    }
  });
};

export const loadStoreResumo = (userId, storeId) => dispatch => {
  const query = storeId === 'all' ? '' : storeId;
  dispatch({
    type: 'API',
    meta: {
      url: `/vendedores/${userId}/resumo?vendedor=${userId}&loja=${query}`,
      key: storeId,
      action: types.LOAD_RESUMO
    }
  });
};

export const loadStoreSchedule = storeId => dispatch => {
  const query = storeId === 'all' ? '' : storeId;
  dispatch({
    type: 'API',
    meta: {
      url: `/vendedores/agendas?id=&loja=${query}`,
      key: storeId,
      action: types.LOAD_SCHEDULE
    }
  });
};

export const loadStoreGroupOverview = (sellerId, storeId) => dispatch => {
  const query = storeId === 'all' ? '' : storeId;
  dispatch({
    type: 'API',
    meta: {
      url: `/lojas/resumo?vendedor=${sellerId}&loja=${query}`,
      key: storeId,
      action: types.LOAD_OVERVIEW
    }
  });
};
