import React from 'react';
import moment from 'moment';
import style from './PanelComments.module.scss';
import Accordion from '../panels/Accordion';

const PanelComments = ({ customer }) => {
  if (!customer.Observacoes || !customer.Observacoes.length) {
    return null;
  }

  console.log(customer);
  return (
    <div>
      <Accordion title="Anotações">
        {customer.Observacoes.map(el => {
          return (
            <React.Fragment>
              <div className={style.commentTitle}>
                {moment(el.DtCriacao).format('DD/MM/YYYY')}
              </div>
              <div className={style.comment}>{el.TextoObservacao}</div>
            </React.Fragment>
          );
        })}
      </Accordion>
    </div>
  );
};

export default PanelComments;
