import React from "react";
import style from "./SelectBox.module.scss";
import Icon from "../general/Icon";
import Loading from "../general/Loading";

const SelectBox = (props) => {
  const items = props.items;

  return (
    <div className={style.selectBoxContainer}>
      <select
        disabled={props.loading || props.disabled}
        className={style.select}
        name={props.name}
        onChange={(e) => props.onChange(e)}
      >
        {props.placeholder && <option value="">{props.placeholder}</option>}
        {items.map((i) => (
          <option value={i.value}>{i.name}</option>
        ))}
      </select>

      <div className={style.arrow}>
        {!props.loading && !props.disabled && (
          <Icon name="arrowDown" size="15px" />
        )}

        {props.loading && <Loading color="#25bcaa" />}
      </div>
    </div>
  );
};

export default SelectBox;
