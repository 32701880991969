import React, { useState } from 'react';
import style from './CPFSearchPanel.module.scss';
import InputButtonRow from './InputButtonRow';
import TextInput from './TextInput';
import { validarCpf } from '../../lib/utils';

const CPFSearchPanel = ({ value, onChange, isLoading, onSubmit, ...props }) => {
  const [cpfError, setCpfError] = useState();
  const [shouldValidate, setShouldValidate] = useState(false);

  const validateCpf = cpf => {
    if (!shouldValidate) {
      return true;
    }

    if (!validarCpf(cpf)) {
      setCpfError('O CPF ou telefone informado é inválido');
      return false;
    }

    setCpfError('');
    return true;
  };

  const wrapOnChange = e => {
    //números + formatação
    if (e.currentTarget.value.length > 12) {
      setShouldValidate(true);
    }
    onChange(e);
    validateCpf(e.currentTarget.value);
  };

  const wrapOnSubmit = () => {
    setShouldValidate(true);
    if (validateCpf(value)) {
      onSubmit();
    }
  };

  const input = (
    <div>
      <TextInput
        name="cpf"
        onChange={wrapOnChange}
        value={value}
        /*mask="999.999.999-99"*/
        placeholder="Insira o CPF ou DDD+celular"
      />
      {cpfError && <div className={style.error}>{cpfError}</div>}
    </div>
  );

  return (
    <div className={style.sectionContent}>
      <InputButtonRow
        input={input}
        buttonText="OK"
        loading={isLoading}
        onSubmit={wrapOnSubmit}
      />
      {props.children}
    </div>
  );
};

export default CPFSearchPanel;
