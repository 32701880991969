import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationMenu from './NavigationMenu';
import ResetPassword from '../../pages/resetPassword';

class PageInternal extends Component {
  constructor(props) {
    super(props);
    this.menuItems = this.getNavigationItems(this.props.session.user.Perfil);
  }

  getNavigationItems = userLevel =>
    [
      {
        link: '/selecionar-vendedor',
        title: 'Seleção de Vendedor',
        level: [2]
      },
      {
        link: '/selecionar-loja',
        title: 'Seleção de Loja / Vendedor',
        level: [3]
      },
      { link: '/dashboard', title: 'Indicadores', level: [1] },
      { link: '/agendas', title: 'Agendas', level: [1] },
      { link: '/avise-me', title: 'Registro de Avise-me', level: [1] },
      {
        link: '/pesquisa-cliente',
        title: 'Pesquisar Cliente',
        level: [1, 2, 3]
          },
      { link: '/anotacoes', title: 'Anotações', level: [1] },
      { link: '/relatorios', title: 'Relatórios', level: [1, 2, 3] },
      { link: '/logout', title: 'Sair', level: [1, 2, 3] }
    ].filter(el => !el.level || el.level.indexOf(userLevel) !== -1);

  render() {
    const passwordUpdateRequired =
      this.props.session && this.props.session.user.TrocaSenha;

    const pageContent = passwordUpdateRequired ? (
      <ResetPassword />
    ) : (
      this.props.children
    );

    return (
      <React.Fragment>
        <NavigationMenu items={this.menuItems} />
        {pageContent}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(PageInternal);
